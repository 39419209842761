import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AlertNotify from '../AlertNotify/AlertNotify';
import config from '../../config.js';
import $ from 'jquery';
// import {is_token_expire} from '../../libs/utils.js';
import Base64  from 'base-64';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "show_valid_code": false,
            "valid_code_img": "",
            "show_alert_notify": false,
            "alert_info": {
                "title": "",
                "content": "",
                "type": "info",
                "position": "top-center",
                "dismiss": ''
            }
        }
    }

    set_show_valid_code = (status) => {
        this.setState({"show_valid_code": status});
    }

    refresh_vaild_code = (base64_code='')=> {
        let username = $("#username").val()
        if (username === '') return;
        if (typeof(base64_code) === 'object') {
            fetch(config.api_url + "/login/refresh_valid_code", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({"username": username})
            })
                .then(res => {
                    return res.json()
                })
                .then(res => {
                    this.setState({"valid_code_img": 'data:image/png;base64,' + res.DATA});
                    $("#valid_code").val('')
                })
        }
        else{
            this.setState({"valid_code_img": 'data:image/png;base64,' + base64_code});
            $("#valid_code").val('')
        }
    }

    set_show_alert_notify = (status)=>{
        this.setState({"show_alert_notify": status})
    }

    login = ()=>{
        let username  = $("#username").val()
        let password = $("#password").val()
        let valid_code = $("#valid_code").val()
        if (username === ''){
            let alert_info = {
                "title": "参数不完整",
                "content": "用户名不可为空",
                "type": "warning",
                "position": "top-center",
                "dismiss": 3000,
                "callback": this.set_show_alert_notify
            }
            this.setState({"alert_info": alert_info, "show_alert_notify": true})
            return
        }
        if (password === ''){
            let alert_info = {
                "title": "参数不完整",
                "content": "密码不可为空",
                "type": "warning",
                "position": "top-center",
                "dismiss": 3000,
                "callback": this.set_show_alert_notify
            }
            this.setState({"alert_info": alert_info, "show_alert_notify": true})
            return
        }
        if (valid_code === '' && this.state.show_valid_code){
            let alert_info = {
                "title": "参数不完整",
                "content": "验证码不可为空",
                "type": "warning",
                "position": "top-center",
                "dismiss": 3000,
                "callback": this.set_show_alert_notify
            }
            this.setState({"alert_info": alert_info, "show_alert_notify": true})
            return
        }
        fetch("/api/auth/user/login",{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: "POST",
            body: JSON.stringify({'username': username, 'password': password, 'valid_code': valid_code})
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            let alert_info;
            if (res.RTN_CODE === 200) {
                alert_info = {
                    "title": "提示",
                    "content": "登录成功",
                    "type": "success",
                    "position": "top-center",
                    "dismiss": 3000,
                    "callback": this.set_show_alert_notify
                }
                window.localStorage.setItem("accessToken", res.data.JWT_TOKEN);
                window.localStorage.setItem("user", username);
                let payload = res.data.JWT_TOKEN.split('.')[1]
                payload = JSON.parse(Base64.decode(payload))
                window.localStorage.setItem('jwt_info', JSON.stringify(payload))
                this.setState({"alert_info": alert_info, "show_alert_notify": true});
                // 在哪儿被退出 下次登录就进哪个页面
                let forward = window.localStorage.getItem('forward')
                if (forward !== null){
                    window.localStorage.removeItem('forward')
                    window.location.href = '/' + forward
                }
                else{
                    window.location.href = '/'
                }
            }
            else{
                alert_info = {
                    "title": "error_code:" + res.RTN_CODE,
                    "content": res.RTN_MSG,
                    "type": "warning",
                    "position": "top-center",
                    "dismiss": 3000,
                    "callback": this.set_show_alert_notify
                }
                this.setState({"alert_info": alert_info, "show_alert_notify": true});
            }
            if (res.RTN_CODE === '00' || res.RTN_CODE === '4033' || res.RTN_CODE === '4035'){
                this.refresh_vaild_code(res.VALID_CODE)
                this.set_show_valid_code(true);
                return
            }
            if (res.RTN_CODE === '4034'){
                this.refresh_vaild_code(res.VALID_CODE)
                this.set_show_valid_code(true);
                return
            }
        }).catch((res)=>{
            let alert_info = {
                "title": "出错了",
                "content": "服务器内部错误",
                "type": "error",
                "position": "top-center",
                "dismiss": 3000,
                "callback": this.set_show_alert_notify
            }
            this.setState({"alert_info": alert_info, "show_alert_notify": true})
        })
    }

    upper_valid_code = ()=>{
        $("#valid_code").val($("#valid_code").val().toUpperCase())
    }
    check_enter = (e) => {
        if(e.keyCode === 13){
            this.login()
        }
    }

    render() {
        return (
            <div className='page-inner' style={{"height": "100vh"}} data-background-color={this.props.theme.body}>
                {this.state.show_alert_notify ? <AlertNotify title={this.state.alert_info.title}
                content={this.state.alert_info.content}
                type={this.state.alert_info.type}
                position={this.state.alert_info.position}
                dismiss={this.state.alert_info.dismiss}
                callback={this.state.alert_info.callback}
                /> :
                null
                }
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <div className='row vertical_align'>
                    <div className='col-md-6 col-sm-12 col-xs-12'>
                        <div className='card'>
                            <div className="card-header">
                                <h3 className="card-title text-center" style={{'fontSize': '30px'}}>
                                    {config.logoText}
                                </h3>
                                {/* <h4 className="card-title text-center">
                                    系统登陆
                                </h4> */}
                            </div>
                            <div className='card-body'>
                                <div className="form-group">
                                    <div className="input-icon">
													<span className="input-icon-addon">
                                                        <FontAwesomeIcon icon="fa fa-user"/>
													</span>
                                        <input type="text" style={{"borderRadius": "50px"}} className="form-control input-border-bottom"
                                               placeholder="用户名" id='username'/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-icon">
													<span className="input-icon-addon">
                                                        <FontAwesomeIcon icon="fa fa-key"/>
                                                    </span>
                                        <input type="password" style={{"borderRadius": "50px"}} className="form-control input-border-bottom "
                                               placeholder="密码" id='password' onKeyDown={(e)=>{this.check_enter(e)}}/>
                                    </div>
                                </div>
                                <div className="form-group input-icon" style={{"display": this.state.show_valid_code ? "" : "none"}}>
                                    <div className="input-group">
                                        <span className="input-icon-addon">
                                            <FontAwesomeIcon  onClick={this.refresh_vaild_code} style={{"cursor": "pointer", "zIndex": "1000"}} icon="fa-solid fa-arrows-rotate" spin/>
                                        </span>
                                        <input type="text" className="form-control input-border-bottom" placeholder="验证码"
                                               id='valid_code' style={{"borderRadius": "50px 0px 0px 50px"}} onChange={this.upper_valid_code} onKeyDown={(e)=>{this.check_enter(e)}}/>
                                        <div className="input-group-prepend" style={{"cursor": "pointer", "zIndex": "1000"}} onClick={this.refresh_vaild_code}>
                                            <img src={this.state.valid_code_img} alt="验证码" className="imagecheck-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer text-center'>
                                <button className="btn btn-success" style={{"borderRadius": "20px"}} onClick={this.login}>
                                    登陆
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
