import React from "react";
import './css/style.css';
import Footer from '../../footer/Footer';
import SystemUser from './SystemUser/SystemUser';
import SystemMenus from './SystemMenus/SystemMenus';
import ApiDocs from './ApiDocs/ApiDocs';
import ApiManagement from './ApiManagement/ApiManagement';
import MenuFunctions from './MenuFunctions/MenuFunctions';
import SystemRole from './SystemRole/SystemRole';
import ReportForm from './Business/ReportForm/ReportForm'
import DepartmentReport from './Business/DepartmentReport/DepartmentReport'
import PhoneReport from './Business/PhoneReport/PhoneReport'
import Device from './Business/Device/Device'
import NoDevice from './Business/Device/NoDevice'
import Department from './Business/Department/Department'
import DePhone from './Business/DePhone/DePhone'
import Export from './Business/Export/Export'



class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "SystemUser": <SystemUser appProps={props} />,
            "SystemMenus": <SystemMenus appProps={props} theme={this.props.theme.body} />,
            "ApiDocs": <ApiDocs appProps={props} theme={this.props.theme.body} />,
            "MenuFunctions": <MenuFunctions appProps={props} theme={this.props.theme.body} />,
            "ApiManagement": <ApiManagement appProps={props} theme={this.props.theme.body} />,
            "SystemRole": <SystemRole appProps={props} theme={this.props.theme.body} />,
            "ReportForm": <ReportForm appProps={props} theme={this.props.theme.body} />,
            "ReportForm2": <SystemRole appProps={props} theme={this.props.theme.body} />,
            "DepartmentReport": <DepartmentReport appProps={props} theme={this.props.theme.body} />,
            "PhoneReport": <PhoneReport appProps={props} theme={this.props.theme.body} />,
            "Device": <Device appProps={props} theme={this.props.theme.body} />,
            "NoDevice": <NoDevice appProps={props} theme={this.props.theme.body} />,
            "Department": <Department appProps={props} theme={this.props.theme.body} />,
            "DePhone": <DePhone appProps={props} theme={this.props.theme.body} />,
            "Export": <Export appProps={props} theme={this.props.theme.body} />,
        }
    }

    no_found = (path)=>{
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title text-center">404 No Found</div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-center p-3">
                                <div id="lineChart">
                                    <canvas width="250" height="100"
                                            style={{"display": "inline-block",
                                                "width": "250px",
                                                "height": "100px",
                                                "vertical-align": "top"}}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        // let grant_menus = window.localStorage.getItem("grant_menus")
        // if (grant_menus === null){
        //     grant_menus = []
        // }
        // else{grant_menus = JSON.parse(grant_menus);}
        // if (grant_menus.indexOf(this.props.current_MainContent) === -1 && this.props.current_MainContent !== 'Dashboard'){
        //     return this.no_found;
        // }
        return (
            <div className="main-panel" data-background-color={this.props.theme.body}>
                {
                    Object.keys(this.state).indexOf(this.props.current_MainContent) === -1 ?
                    this.no_found : 
                    this.state[this.props.current_MainContent]
                }
                <Footer/>
            </div>
        )
    }
}

export default MainContent;