import React, {Component} from "react";
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './css/style.css';
import $ from 'jquery';
// rule : [{
    //             "label_name": "姓名",
    //             "component": "input",
    //             "type": "text",
    //             "id": "name",
    //             "disable": false,
    //             "value": "",
    //             "must": true,
    //             "nullable": false,
    //             "optionData":[{"value": "", "name": ""}]
    //          },...]

export default class TableSearchFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "data": {},
            "rule": this.props.rule,
            "callback": this.props.onSearch,
            "other_op": props.other_op,
            "clearAll": props.clearAll,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                'rule': this.props.rule,
            })
        }
    }


    clearAll = (e) => {
          this.state.rule.forEach( item=>{
            if (item.type === 'select'){
                $("#" + item.id + '>div>div>div:first-child')[0].innerText = '请选择'
            }
            else {
                $("#" + item.id).val('')
            }
        })
        this.setState({"data": {}})
        this.state.callback({})
        // console.log(this.props.clearAll);
        if (typeof(this.state.clearAll) === 'function') {
            this.state.clearAll()
        }
        
    }
    search = (e) => {
        this.state.callback(this.state.data)
    }
    OnSearch = (e) => {
      let event = e || window.event;
      let  key = event.which || event.keyCode || event.charCode;
      if (key === 13) {
            this.state.callback(this.state.data)
      }
    }

    select_onchange = (id, e, field) => {
        let data = this.state.data;
        if (e.value !== null && e.value !== undefined) {
            if (field === 'label')
                data[id] = e.label
            else if (field === 'value')
                data[id] = e.value
            else
                data[id] = e
        }
        else{
            delete data[id];
        }
        this.setState({"data" :data});
    }

    input_onchange = (id, e) => {
        let data = this.state.data;
        if (e.target.value === '')
            delete data[id]
        else
            data[id] = e.target.value.trim()
        this.setState({"data" :data});


}

    render () {
        // console.log(this.state.rule)
        return <div className='row col-md-12' style={{"padding": '5px 5px 0 10px', alignItems: 'center'}}>
        {
            this.state.rule.map(item => {
                if (typeof (item.callback) === 'function') {
                    return item.callback(item)      // 回调用自己的component
                }
                else if (item.type === 'select') {
                    return <div key={item.id} className="form-group row col-md-3">
                <label htmlFor={item.id} className={item.label_name.length > 2 ? "col-md-3 col-form-label" : "col-md-2 col-form-label"} style={{"letterSpacing": '2px', "lineHeight": "2.5em", "padding": '5px 0px 0px 5px', "margin": 0}}>{item.label_name}</label>
                <div className="col-md-9" style={{"padding": "3px 5px 0px 5px"}}>
                    <Select
                            onChange={e=>{
                                this.select_onchange(item.id, e, item.field)
                                if (typeof(item.onchange) === 'function'){
                                    item.onchange(e, item.field)
                                }
                            }}
                            id={item.id}
                            options={item.optionData}
                            placeholder='请选择'
                            style={{'zIndex': 2000}}
                            className="select_bottom_line basic-multi-select "
                            classNamePrefix="select"
                            onFocus={e=>{
                                if (typeof (item.onFocus) === 'function') {
                                    item.onFocus(e)
                            }}}
                        />
                </div>
            </div>
                }
                else return <div key={item.id} className="form-group row col-md-3">
                    <label htmlFor={item.id} className={item.label_name.length > 2 ? "col-md-3 col-form-label" : "col-md-2 col-form-label"} style={{"letterSpacing": '2px', "lineHeight": "2.5em", "padding": '5px 0px 0px 5px', "margin": 0}}>{item.label_name}</label>
                    <div className="col-md-9">
                        <input type={item.type} id={item.id}
                               onChange={e=>{this.input_onchange(item.id, e)}}
                               defaultValue={item.value} disabled={item.disable ? 'true' : ''}
                               className="table-search-input form-control input-border-bottom" autoComplete='off'
                               style={{"paddingBottom": '4px'}}
                               onKeyUp={e=>{this.OnSearch(e)}}
                        />
                    </div>
                </div>
        })
        }
{/*         <div className='form-group form-floating-label col-md-2 text-left' style={{"marginTop": '5px'}}>
            <button className='btn btn-primary btn-sm' onClick={e => {
                this.search(e)
            }}><FontAwesomeIcon icon="fa fa-magnifying-glass" size='xl'/>
            </button>
            <button className='btn btn-info btn-sm' onClick={e=>{
                this.clearAll(e)
            }}>清空搜索
            </button>
        </div> */}
        <div className={typeof(this.state.other_op) === 'function' ? 'form-group form-floating-label text-left' : 'form-group form-floating-label text-left'} style={{"marginTop": '5px','display': 'inline-block','width': 'auto'}}>
        {/* <div className={typeof(this.state.other_op) === 'function' ? 'form-group form-floating-label col-md-4 text-left' : 'form-group form-floating-label col-md-3 text-left'} style={{"marginTop": '5px'}}> */}
            <button className='btn btn-primary btn-sm' onClick={e => {
                this.search(e)
            }}><FontAwesomeIcon icon="fa fa-magnifying-glass" size='xl'/>
            </button>
            <button className='btn btn-info btn-sm' onClick={e=>{
                this.clearAll(e)
            }}>清空搜索
            </button>
            {typeof(this.state.other_op) === 'function' ? this.state.other_op() : null}
        </div>
    </div>
    }
}