import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import PubSub from 'pubsub-js';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {confirm} from 'react-bootstrap-confirmation';



export default class reportForm extends Component {
    constructor(props) {
        // let func = {"add": 103, 'edit': 104, 'get': 105, 'chongzhi': 106, 'selectchonglog': 107, 'del': 131}
        // func = func_privileges(func)
        super(props);
        this.state = {
            // "func": func,
            name: "报表",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "公司名称",
                    "selector": row => row.gsmc,
                    center: true,
                },
                {
                    "name": "余额（元）",
                    "selector": row => row.je.toFixed(2),
                    center: true,
                },
                {
                    "name": "更新时间",
                    "selector": row => row.gxsj,
                    center: true,
                },
                {
                    "name": "备注",
                    "selector": row => row.bz,
                    center: true,
                },
                {
                    "name": "操作",
                    "center": true,
                    width: '230px',
                    "selector": row =>
                        <div>
                            {this.state.func.edit ? <><span className='caozuoHover' style={{
                                                                         "cursor": "pointer",
                                                                     }} onClick={e => {
                                this.edit_gs(e, row)
                            }}>编辑</span>  | </>: null}
                            {this.state.func.chongzhi ? <><span className='caozuoHover' style={{
                                                             "cursor": "pointer",
                                                             "marginLeft": '5px'
                                                         }} onClick={()=>this.payment(row.id)
                                }>充值</span>  | </>: null}
                            {this.state.func.selectchonglog ? <><span className='caozuoHover' style={{
                                                                "cursor": "pointer",
                                                                "marginLeft": '5px'
                                                                }} onClick={e => {
                                this.setState({bootstrapModal: {...this.state.bootstrapModal, gsid: parseInt(row.id.substring(row.id.indexOf("-") + 1))}},
                                ()=>this.view_jl())
                            }}>充值记录</span>  | </>: null}
                            { this.state.func.del ? <span className='caozuoHover' style={{
                                                             "cursor": "pointer",
                                                             "marginLeft": '5px'
                                                         }} onClick={()=>{
                                                            this.deleteRow(row)
                                                        }
                                }>删除</span> : null}
                        </div>,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                {
                    "label_name": "公司名称",
                    "type": "text",
                    "id": "search_gsmc",
                    "value": "",
                    "must": false,
                    "nullable": true,
                },
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        // this.get_gs();
        // this.get_zuzhi();
    }

    add_gs = (e) => {
        const { search_zuzhi } = this.state
        PubSub.publish('Loding', true);
        this.setState({
            "modal_show": true,
            "modal_title": "新增公司",
            "modal_rules": [
                {
                    "label_name": "公司名称",
                    "type": "text",
                    "id": "gsmc",
                    "value": "",
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入公司名称",
                },
                // {
                //     "label_name": "联系人名称",
                //     "type": "text",
                //     "id": "lxr",
                //     "value": "",
                //     "must": true,
                //     "nullable": false,
                //     "placeholder": "请输入联系人名称",
                // },
                // {
                //     "label_name": "联系电话",
                //     "type": "text",
                //     "id": "dh",
                //     "value": "",
                //     "must": true,
                //     "nullable": false,
                //     "placeholder": "请输入联系电话",
                // },
                {
                    "label_name": "请选择上级组织",
                    "type": "select",
                    "id": "zzid",
                    "value": "",
                    "field":'value',
                    "must": true,
                    "nullable": false,
                    'optionData': search_zuzhi,
                    "placeholder": "请选择上级组织",
                },
                {
                    "label_name": "备注",
                    "type": "textarea",
                    "id": "bz",
                    "value": "",
                    "must": false,
                    "nullable": true,
                    "placeholder": "请输入备注",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                http_call('/api/gsgl/add_gs', 'POST', data).then(res => {
                    let alert_info;
                    if (res.RTN_CODE === '200') {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "success",
                            "position": "top-center",
                            "dismiss": 3000,
                        }
                        this.setState({"modal_show": false})
                        this.get_gs()
                    } else {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "error",
                            "position": "top-center",
                            "dismiss": '',
                        }
                    }
                    PubSub.publish('AlertNotify', alert_info);
                })
            },
        });
        PubSub.publish('Loding');
    }

    edit_gs = (e, row) => {
        // console.log(row);
        PubSub.publish('Loding', true);
        const {gsmc, pid, bz, id} = row;
        const { search_zuzhi } = this.state;
        let zzid = parseInt(pid.substring(pid.indexOf("-") + 1))
        let option = (search_zuzhi.find(item => item.value === zzid)) ? search_zuzhi.find(item => item.value === zzid) : {value: '', label: '请选择'};
        PubSub.publish('Loding');
        this.setState({
            "modal_show": true,
            "modal_title": "修改公司",
            "modal_rules": [
                {
                    "label_name": "公司名称",
                    "type": "text",
                    "id": "gsmc",
                    "value": gsmc,
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入公司名称",
                },
                {
                    "label_name": "请选择上级组织",
                    "type": "select",
                    "id": "zzid",
                    "value": option,
                    "field":'value',
                    "must": true,
                    "nullable": false,
                    'optionData': search_zuzhi,
                    "placeholder": "请选择上级组织",
                },
                {
                    "label_name": "备注",
                    "type": "textarea",
                    "id": "bz",
                    "value": bz,
                    "must": false,
                    "nullable": true,
                    "placeholder": "请输入备注",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                data.id = parseInt(id.substring(id.indexOf("-") + 1))
                http_call('/api/gsgl/up_gs', 'POST', data).then(res => {
                    let alert_info;
                    if (res.RTN_CODE === '200') {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "success",
                            "position": "top-center",
                            "dismiss": 3000,
                        }
                        this.setState({"modal_show": false})
                        this.get_gs()
                    } else {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "error",
                            "position": "top-center",
                            "dismiss": '',
                        }
                    }
                    PubSub.publish('AlertNotify', alert_info);
                })
            },
        });
    }

    deleteRow = async(row) =>{
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
        {
          "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
          "okText": "确认",
          okButtonStyle: 'warning',
          "cancelText": "取消"
        });
        if (result) {
            http_call('/api/log/dels', 'POST', {lx: 1, id: parseInt(row.id.split('-')[1])}).then(res=>{
                let alert_info;
                if (res.RTN_CODE === '200') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-center",
                        "dismiss": 3000,
                    }
                    this.get_gs();
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '',
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_gs = (page = 1, limit = 10) => {
        PubSub.publish('Loding', true);
        http_call('/api/gsgl/get_gslb', 'GET', {page, limit}).then(res => {
            this.setState({"tableData": res.DATA, TotalRows: res.COUNT})
            PubSub.publish('Loding');
        })
    }

    inquiry_gs = (data) => {
        let DATA = {}
        PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        http_call('/api/gsgl/get_gslb', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }
    
    
    
    render() {
        
            const props = {
                name: 'file',
                action: '/api/biz/telephone/link',
                headers: {
                  authorization: "Bearer "+  window.localStorage.getItem('accessToken'),
                },
                onChange(info) {
                  if (info.file.status !== 'uploading') {
                    // console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                },
              };
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            {/* <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>预付费管理</span>
                            </li> */}
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    {false ? <button className='btn btn-primary btn-xs'
                                                                   onClick={e => {
                                                                       this.add_gs(e);
                                                                   }
                                                                   }>新增公司
                                    </button> : null}
                                    <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {
                                        this.inquiry_gs(data)
                                    }} other_op={()=>{
                                        return <Upload {...props}>
                                            <Button icon={<UploadOutlined />}>上传</Button>
                                        </Upload>
                                    }}
                                    />
                                </div>
                                <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.TotalRows}
                                    paginationComponentOptions={{
                                        rowsPerPageText: '每页行数:',
                                        rangeSeparatorText: '/',
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: '全部'
                                    }}
                                    fixedHeader
                                    dense={false}
                                    fixedHeaderScrollHeight={"100vh"}
                                    theme=""
                                    onChangeRowsPerPage={(newPerPage, page) => this.get_gs(page, newPerPage)}
                                    onChangePage={(page) => this.get_gs(page)}
                                    onSelectedRowsChange={(rows) => {
                                        this.setState({"selectRows": rows})
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
