import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call} from '../../../../../libs/utils';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';
import $ from 'jquery';



export default class Device extends Component {
    constructor(props) {
        super(props);
        let tt = new Date()
        let nowMonth = `${tt.getFullYear()}-${('0'+(tt.getMonth()+1)).slice(-2)}`
        this.state = {
            // "func": func,
            name: "导入记录",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            tableData: [],
            column: [
                {
                    "name": "文件名",
                    "selector": row => row.uploadFileName,
                    center: true,
                },
                {
                    "name": "行数",
                    "selector": row => row.extInt1,
                    center: true,
                },
                {
                    "name": "金额合计（元）",
                    "selector": row => (row.extInt2/100).toFixed(2),
                    center: true,
                },
                {
                    "name": "上传时间",
                    "selector": row => row.createdTime.replaceAll("T", " "),
                    center: true,
                },
                {
                    "name": "操作人",
                    "selector": row => row.creator ? row.creator.username : '',
                    center: true,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                // {
                //     "label_name": "月份",
                //     "type": "month",
                //     "id": "search_telephone",
                //     "value": '',
                //     // "value": nowMonth,
                //     "must": false,
                //     "nullable": true,
                // },
            ],
            nowMonth: nowMonth,
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_jilu();
    }

    get_jilu = (page=1, limit=10) => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/upload/search', 'POST', {page, limit,sorter:{column: 'createdTime', asc: false} }).then(res => {
            this.setState({"tableData": res.data.records, TotalRows: res.data.count})
            PubSub.publish('Loding');
        })
    }

    // searchDC = () => {
    //     PubSub.publish('Loding', true);
    //     http_call('/api/biz/statistics/telephone-bill-export', 'POST', {date: this.state.nowMonth, ...this.state.seachForm}).then(res => {
    //         // this.setState({"tableData": res.DATA, TotalRows: res.COUNT})
    //         res.blob().then(res => {
    //             const blob = new Blob([res], {type: "application/vnd.ms-excel"})
    //             const url = URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.download = 'example.xlsx';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             URL.revokeObjectURL(url);
    //         })
    //         PubSub.publish('Loding');
    //     })
    // }

    inquiry_device = (data) => {
        PubSub.publish('Loding', true);
        const DATA = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('search_')) {
                acc[key.replace('search_', '')] = data[key];
            }
            return acc;
        }, {});
        this.setState({seachForm: DATA}, ()=>this.get_jilu());
        PubSub.publish('Loding');
    }

    getFilds = () =>{
        const filedom = document.getElementById('file');
        filedom.click()
    }

    // 用于监听按钮上传的事件
    fileinputChange = (event) =>{
        const fileData = event.target.files[0];
        // 获取到的文件 fileData
        if(fileData){
          this.setState({ fileData, })
          const formdata = new FormData();
          formdata.append("wordType",3);
          formdata.append("file",fileData);
          this.send(formdata)
        }
    }

    send =  async (formdata) =>{
        const url = "/api/biz/call-bill/upload";
        fetch(url, {
        method:'post',
        headers:{
            "Authorization": "Bearer " + window.localStorage.getItem('accessToken')
        },
        body:formdata,
        }).then(res=>res.json()).then(res=>{
            // 处理返回值
            let alert_info;
            if (res.RTN_CODE === 200) {
                alert_info = {
                    "title": "提示",
                    "content": '上传成功！',
                    "type": "success",
                    "position": "top-center",
                    "dismiss": 2000,
                }
                this.get_jilu()
            } else {
                alert_info = {
                    "title": "提示",
                    "content": '上传失败！',
                    "type": "error",
                    "position": "top-center",
                    "dismiss": 3000,
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        })
    }
    
    
    render() {
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    <div>
                                        <input id='file' type='file'  accept=".xls,.xlsx" style={{ display:"none" }} onChange={this.fileinputChange} />
                                        <button className='btn btn-primary btn-sm' onClick={this.getFilds}>上传Excel</button>
                                    </div>
                                    {/* <TableSearchFields rule={this.state.search_rule}  onSearch={(data) => {
                                        this.inquiry_device(data)
                                    }} /> */}
                                </div>
                                <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.TotalRows}
                                    paginationComponentOptions={{
                                        rowsPerPageText: '每页行数:',
                                        rangeSeparatorText: '/',
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: '全部'
                                    }}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight={"100vh"}
                                    dense={false}
                                    theme=""
                                    onChangeRowsPerPage={(newPerPage, page) => this.get_jilu(page, newPerPage)}
                                    onChangePage={(page) => this.get_jilu(page)}
                                    // onSelectedRowsChange={(rows) => {
                                    //     this.setState({"selectRows": rows})
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
