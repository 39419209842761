import React from 'react';
import BootstrapTreeTable from 'bootstrap-react-treetable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {api_call} from '../../../../libs/utils.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import './css/style.css';



class ApiDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "接口文档",
            "column": [
                {
                    "dataField": "menu",
                    "heading": "菜单",
                },
                // {
                //     "dataField": "sub_menu",
                //     "heading": "子菜单",
                // },
                {
                    "dataField": "functionname",
                    "heading": "功能"
                },
                {
                    "dataField": "apiname",
                    "heading": "API名称"
                },
                {
                    "dataField": "api_description",
                    "heading": "api描述"
                },
                {
                    "dataField": "apipath",
                    "heading": "api路径"
                },
                {
                    "dataField": "method",
                    "heading": "调用方式"
                },
                {
                    "dataField": "",
                    "heading": "操作",
                    "renderer":  (dataRow, dataField) => {
                        if (dataRow.data.apiname !== '') {
                            return <div>
                            <button style={{"marginRight": '5px'}} className='btn btn-primary btn-sm' onClick={e => {
                                this.show_parameters_detail(e, dataRow.data.apiid)
                            }}>查看参数</button>
                            </div>
                        }
                    }
                }
            ],
            "control": {
                "visibleRows": 1,
                "allowSorting": false,
                "showPagination": true,
                "initialRowsPerPage": 10,
                "allowFiltering": false,
                "showExpandCollapseButton": true
            },
            "show_parameters_detail": false,
            "modal_title": "详情",
            "input_doc":[],
            "output_doc":[],
            "rtn_doc":[],
            "api_description": ""
        }
    }

    set_tableData = (res) => {
        this.setState({"tableData": res.DATA});
    }


    hide_parameters_detail = (e)=>{
        this.setState({"show_parameters_detail": false})
    }
    set_parameters_detail = (res)=>{
        let data = res.DATA[0];
        let input_doc = [];
        let output_doc = [];
        let returncode = [];
        if (data.input_parameters !== null) {
          Object.keys(data.input_parameters).forEach((key)=>{
              data.input_parameters[key]['fieldname'] = key
              input_doc.push(data.input_parameters[key])
          });
        }
        if (data.output_parameters !== null) {
          Object.keys(data.output_parameters).forEach((key)=>{
              data.output_parameters[key]['fieldname'] = key
              output_doc.push(data.output_parameters[key])

          });
        }
        if (data.returncode !== null) {
          Object.keys(data.returncode).forEach((key)=>{
              data.returncode[key]['fieldname'] = key
              returncode.push(data.returncode[key])
          });
        }
        this.setState({"input_doc": input_doc});
        this.setState({"output_doc": output_doc});
        this.setState({"rtn_doc": returncode});
        this.setState({"api_description": data.description});
        this.setState({"modal_title": data.apiname + '(' + data.apipath + ')详情'});
    }

    show_parameters_detail = (e, apiid)=>{
        api_call('/apidocs/parameters?id=' + apiid, 'GET', this.set_parameters_detail)
        this.setState({"show_parameters_detail": true})
    }


    componentDidMount() {
        api_call('/apidocs', 'GET', this.set_tableData);
        $("table").css({"height": $(window).height(), "overflow": "scroll"})
    }

    render() {
        return (
            <div className='page-inner' data-background-color={this.props.theme.body}>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa-brands fa-dev"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>开发配置</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='table-responsive' >
                    {this.state.tableData !== undefined ?
                        <BootstrapTreeTable  columns={this.state.column} tableData={this.state.tableData}
                                        control={this.state.control}/>
                        : <h1 className='text-center'>暂无数据</h1>
                    }
                    </div>
                  <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  show={this.state.show_parameters_detail}
                >
                  <Modal.Header >
                    <Modal.Title>
                        {this.state.modal_title}
                        <p style={{'color': 'red'}}>1.参数大小写敏感 2.出入参数均为json数据格式</p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h3>描述:</h3>
                      <p style={{"color": 'red'}}>{this.state.api_description}</p>
                    <h3>入口参数</h3>
                    <div className='table-responsive'>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>字段名称</th>
                              <th>字段解释</th>
                              <th>数据类型</th>
                              <th>必填</th>
                              <th>可空</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            this.state.input_doc.map(item=>{
                               return <tr>
                                      <td>{item.fieldname}</td>
                                      <td>{item.aliasName}</td>
                                      <td>{item.type}</td>
                                      <td>{String(item.must)}</td>
                                      <td>{String(item.nullable)}</td>
                                   </tr>
                            })
                          }
                          </tbody>
                        </Table>
                    </div>
                    <h3>出口参数</h3>
                  <div className='table-responsive'>
                        <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>字段名称</th>
                          <th>字段解释</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.output_doc.map(item=>{
                           return <tr>
                                  <td>{item.fieldname}</td>
                                  <td>{item.aliasName}</td>
                               </tr>
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                    <h3>状态代码</h3>
                  <div className='table-responsive'>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>CODE</th>
                          <th>字段解释</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.rtn_doc.map(item=>{
                           return <tr>
                                  <td>{item.fieldname}</td>
                                  <td>{item.aliasName}</td>
                               </tr>
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className='btn-danger' onClick={this.hide_parameters_detail} size='sm'>关闭</Button>
                  </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


export default ApiDocs;