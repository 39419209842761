let config = {
    // "api_url": "http://192.168.5.151:8080",
    // "media_url": "http://192.168.5.151:8080",
    "api_url": 'http://192.168.0.22:8080',
    "logoText": "物业电话管理",
    "footerText": "物业电话管理系统",
    "copyright": "",
    // "copyright": "Copyright © hssbgl",
}
export default config
