import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import Zuzhi from "@mui/icons-material/Business";
import Gongsi from "@mui/icons-material/Store";
import Shanghu from "@mui/icons-material/Storefront";
import Defa from "@mui/icons-material/Lightbulb";

export const TypeIcon = (props) => {
/*   if (props.droppable) {
    return <FolderIcon />;
  }

  switch (props.fileType) {
    case "image":
      return <ImageIcon />;
    case "csv":
      return <ListAltIcon />;
    case "text":
      return <DescriptionIcon />;
    default:
      return null;
  } */
  switch (props.jb) {
    case 1:
      return <Zuzhi />;
    case 2:
      return <Gongsi style={{'color': '	#0066FF'}} />;
    case 3:
      return <Shanghu  style={{'color': '	#FFBB00'}} />;
    default:
      return <Defa />;
  }
};
