import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call} from '../../../../../libs/utils';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';
import $ from 'jquery';
import { Cascader } from 'antd';



export default class Device extends Component {
    constructor(props) {
        super(props);
        let tt = new Date()
        let nowMonth = `${tt.getFullYear()}-${('0'+(tt.getMonth()+1)).slice(-2)}`
        this.state = {
            // "func": func,
            name: "已绑定电话",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "电话",
                    "selector": row => row.telephone,
                    center: true,
                },
                {
                    "name": "类型",
                    "selector": row => row.type === 1 ? '内线' : row.type === 2 ? '外线' : '未知',
                    center: true,
                },
                {
                    "name": "所属部门",
                    "selector": row => row.organization.title,
                    center: true,
                },
                {
                    "name": "操作",
                    "width": "180px",
                    center: true,
                    omit: false,
                    "selector": row =>
                        <>
                            {true ? <><span className='caozuoHover' style={{
                                                         "cursor": "pointer",
                                                     }} onClick={()=>this.edit_device(row)
                            }>编辑</span>  | </>: null}
                            { true ? <span className='caozuoHover' style={{
                                                         "cursor": "pointer",
                                                         "marginLeft": '5px'
                                                     }} onClick={()=>{ this.del_device(row) }
                            }>删除</span> : null}
                        </>,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                {
                    "label_name": "电话",
                    "type": "text",
                    "id": "search_telephone",
                    "value": '',
                    // "value": nowMonth,
                    "must": false,
                    "nullable": true,
                },
            ],
            seachForm: {},
            nowMonth: nowMonth,
            selectRows: {},
            selectDepat: '',
            selectOption: [],
            AddDepat: '',
            type: [
                {value: 0, label: '未知'},
                {value: 1, label: '内线'},
                {value: 2, label: '外线'},
            ], //  0不知道，，1内线，， 2外线
        }
    }

    componentDidMount() {
        this.get_device();
        this.get_option();
    }

    get_device = (page=1, limit=10) => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/telephone/search', 'POST', {page, limit, orgIdIsNull: false,sorters: [{column: "orgId"},{column: "telephone"}], filters: {...this.state.seachForm, orgId: this.state.selectDepat} }).then(res => {
            this.setState({"tableData": res.data.records, TotalRows: res.data.count})
            PubSub.publish('Loding');
        })
    }

    get_option = () => {
        http_call('/api/biz/organization/option', 'POST', { sorter: {column: "orgId"} }).then(res => {
            this.setState({"selectOption": res.data})
            this.setState({"search_rule": [
                ...this.state.search_rule,
                {
                    "label_name": "所属部门",
                    "type": "text",
                    "id": "search_telephone",
                    "value": '',
                    "must": false,
                    "nullable": true,
                    "callback": ()=>{
                        return <div style={{height: '100%', width: '25%', padding: '10px'}}>
                            所属部门<Cascader changeOnSelect={true} showSearch={true} bordered={false} style={{borderBottom: '1px solid #d7d6d6', width: '70%'}} options={res.data} onChange={(value)=>{this.setState({selectDepat: value?value[value.length-1]:''})}} placeholder="搜索部门" />
                        </div>
                    }
                },
            ]})
        })
    }

    // searchDC = () => {
    //     PubSub.publish('Loding', true);
    //     http_call('/api/biz/statistics/telephone-bill-export', 'POST', {date: this.state.nowMonth, ...this.state.seachForm}).then(res => {
    //         // this.setState({"tableData": res.DATA, TotalRows: res.COUNT})
    //         res.blob().then(res => {
    //             const blob = new Blob([res], {type: "application/vnd.ms-excel"})
    //             const url = URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.download = 'example.xlsx';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             URL.revokeObjectURL(url);
    //         })
    //         PubSub.publish('Loding');
    //     })
    // }

    // 清空select框
    clearAll = () => {
        this.setState({selectDepat: ''})
        // $(".ant-select-selection-item").attr("class", "ant-select-selection-placeholder");
        // $(".ant-select-selection-placeholder").innerText = '搜索部门'
        if (($(".ant-select-selection-item")[0])) {
            $(".ant-select-selection-item")[0].innerText = '搜索部门'
        }
    }

    inquiry_device = (data) => {
        PubSub.publish('Loding', true);
        const DATA = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('search_')) {
                acc[key.replace('search_', '')] = data[key];
            }
            return acc;
        }, {});
        this.setState({seachForm: DATA}, ()=>this.get_device());
        PubSub.publish('Loding');
    }

    add_device = () => {
        // console.log(row);
        let AddDepat = ''
        this.setState({
            "modal_show": true,
            "modal_title": "新增电话",
            "modal_rules": [
                {
                    "label_name": "电话号码",
                    "type": "text",
                    "id": "telephone",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入电话号码",
                },
                {
                    "label_name": "请选择所属部门",
                    "type": "select",
                    "id": "orgId",
                    "value": {},
                    "field":'value',
                    "must": false,
                    "nullable": true,
                    'optionData': [],
                    "placeholder": "请选择所属部门",
                    "callback": ()=>{
                        return <Cascader dropdownHeight={300} changeOnSelect={true} showSearch={true} bordered={false} style={{borderBottom: '1px solid black', width: '100%'}} options={this.state.selectOption} onChange={(value)=>{ AddDepat= value?value[value.length-1]:'' }} placeholder="搜索部门" />
                    }
                },
                {
                    "label_name": "请选择类型",
                    "type": "select",
                    "id": "type",
                    "value": '',
                    "field":'value',
                    "must": true,
                    "nullable": false,
                    'optionData': this.state.type,
                    "placeholder": "请选择类型",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                http_call('/api/biz/telephone/create', 'POST', {telephone: data.telephone,type: data.type, orgId: AddDepat}).then(res => {
                    let alert_info;
                    if (res.RTN_CODE === 200) {
                        alert_info = {
                            "title": "提示",
                            "content": '操作成功！',
                            "type": "success",
                            "position": "top-center",
                            "dismiss": 3000,
                        }
                        this.setState({"modal_show": false})
                        this.get_device()
                    } else {
                        alert_info = {
                            "title": "提示",
                            "content": '操作失败！',
                            "type": "error",
                            "position": "top-center",
                            "dismiss": '',
                        }
                    }
                    PubSub.publish('AlertNotify', alert_info);
                })
            },
        });
    }

    edit_device = (row) => { 
        let AddDepat = row.orgId
        this.setState({
            "modal_show": true,
            "modal_title": "修改电话",
            "modal_rules": [
                {
                    "label_name": "电话",
                    "type": "text",
                    "id": "telephone",
                    "value": row.telephone,
                    "disable": true,
                    "must": false,
                    "nullable": true,
                    "placeholder": "请输入电话",
                },
                {
                    "label_name": "请选择所属部门",
                    "type": "text",
                    "id": "orgId",
                    "value": '111',
                    "must": true,
                    "nullable": false,
                    "placeholder": "请选择所属部门",
                    "callback": ()=>{
                        // 67840850
                                return <Cascader dropdownHeight={300} defaultValue={[row.organization.title]} changeOnSelect={true} showSearch={true} bordered={false} style={{borderBottom: '1px solid black', width: '100%'}} options={this.state.selectOption} onChange={(value)=>{ AddDepat= value?value[value.length-1]:'' }} placeholder="搜索部门" />
                    }
                },
                {
                    "label_name": "类型",
                    "type": "select",
                    "id": "type",
                    "value": this.state.type.find((it)=>it.value === row.type),
                    "field":'value',
                    "must": true,
                    "nullable": false,
                    'optionData': this.state.type,
                    "placeholder": "请选择类型",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                http_call('/api/biz/telephone/edit-org', 'POST', {id: row.id, orgId: AddDepat, type: data.type}).then(res => {
                    let alert_info;
                    if (res.RTN_CODE === 200) {
                        alert_info = {
                            "title": "提示",
                            "content": '操作成功！',
                            "type": "success",
                            "position": "top-center",
                            "dismiss": 3000,
                        }
                        this.setState({"modal_show": false})
                        this.get_device()
                    } else {
                        alert_info = {
                            "title": "提示",
                            "content": '操作失败！',
                            "type": "error",
                            "position": "top-center",
                            "dismiss": '',
                        }
                    }
                    PubSub.publish('AlertNotify', alert_info);
                })
            },
        });
    }

    del_device = async(row) =>{
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
        {
          "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
          "okText": "确认",
          okButtonStyle: 'warning',
          "cancelText": "取消"
        });
        if (result) {
            http_call('/api/biz/telephone/remove', 'POST', {id: row.id}).then(res=>{
                let alert_info;
                if (res.RTN_CODE === 200) {
                    alert_info = {
                        "title": "提示",
                        "content": '操作成功！',
                        "type": "success",
                        "position": "top-center",
                        "dismiss": 3000,
                    }
                    this.get_device();
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": '操作失败！',
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '',
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    
    getFilds = () =>{
        const filedom = document.getElementById('file');
        filedom.click()
    }

    // 用于监听按钮上传的事件
    fileinputChange = (event) =>{
        const fileData = event.target.files[0];
        // 获取到的文件 fileData
        if(fileData){
          this.setState({ fileData, })
          const formdata = new FormData();
          formdata.append("wordType",3);
          formdata.append("file",fileData);
          this.send(formdata)
        }
    }

    send =  async (formdata) =>{
        const url = "/api/biz/telephone/upload";
        fetch(url, {
        method:'post',
        headers:{
            "Authorization": "Bearer " + window.localStorage.getItem('accessToken')
        },
        body:formdata,
        }).then(res=>res.json()).then(res=>{
            // 处理返回值
            let alert_info;
            if (res.RTN_CODE === 200) {
                alert_info = {
                    "title": "提示",
                    "content": '上传成功！',
                    "type": "success",
                    "position": "top-center",
                    "dismiss": 3000,
                }
                this.get_device();
            } else {
                alert_info = {
                    "title": "提示",
                    "content": '上传失败！',
                    "type": "error",
                    "position": "top-center",
                    "dismiss": '3000',
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        })
    }

    dc = () => {
        PubSub.publish('Loding', true);
        fetch("/api/biz/telephone/export", {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + window.localStorage.getItem('accessToken')
            },
            body: JSON.stringify({page: 1, limit: 10000, orgIdIsNull: false})
        }).then(response => {
            response.blob().then(res => {
                const blob = new Blob([res], {type: "application/vnd.ms-excel"})
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = '电话.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            PubSub.publish('Loding');
        })
    }
    
    
    
    render() {
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>电话管理</span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    {false ? <button className='btn btn-primary btn-xs'
                                                                   onClick={e => {
                                                                       this.add_device();
                                                                   }
                                                                   }>新增电话
                                    </button> : null}
                                    <TableSearchFields rule={this.state.search_rule} clearAll={()=>{this.clearAll()}} onSearch={(data) => {
                                        this.inquiry_device(data)
                                    }} other_op={()=>{
                                        return <>
                                            {/* <button className='btn btn-primary btn-sm' onClick={e => { this.searchDC() }}>导出 </button> */}
                                            <div style={{display: 'inline-block'}}>
                                                <input id='file' type='file'  accept=".xls,.xlsx" style={{ display:"none" }} onChange={this.fileinputChange} />
                                                <button className='btn btn-primary btn-sm' onClick={this.getFilds}>上传Excel</button>
                                                <button className='btn btn-primary btn-sm' onClick={this.dc}>导出</button>
                                            </div>
                                        </>
                                    }}
                                    />
                                </div>
                                <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.TotalRows}
                                    paginationComponentOptions={{
                                        rowsPerPageText: '每页行数:',
                                        rangeSeparatorText: '/',
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: '全部'
                                    }}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight={"100vh"}
                                    dense={false}
                                    theme=""
                                    onChangeRowsPerPage={(newPerPage, page) => this.get_device(page, newPerPage)}
                                    onChangePage={(page) => this.get_device(page)}
                                    // onSelectedRowsChange={(rows) => {
                                    //     this.setState({"selectRows": rows})
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
