import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';
import TableSearchFields from '../../../../components/TableSearchFields/TableSearchFields';
import DynamicModal from '../../../../components/DynamicModal/DynamicModal';
import {http_call} from '../../../../libs/utils';



export default class SystemUser extends Component {
    constructor(props) {
        super(props);
        let tt = new Date()
        this.state = {
            // "func": func,
            name: "用户管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "用户名",
                    "selector": row => row.username,
                    center: true,
                },
                // {
                //     "name": "状态",
                //     "selector": row => row.status === 1 ? <span style={{color: 'green'}}>活动</span> : <span style={{color: 'red'}}>禁用</span>,
                //     center: true,
                // },
                {
                    "name": "创建时间",
                    "selector": row => row.createdTime.replaceAll('T', ' '),
                    center: true,
                },
                {
                    "name": "操作",
                    "width": "180px",
                    center: true,
                    omit: false,
                    "selector": row =>
                        <>
                            {true ? <><span className='caozuoHover' style={{
                                                         "cursor": "pointer",
                                                     }} onClick={()=>this.edit_user(row)
                            }>编辑</span>  | </>: null}
                            { true ? <span className='caozuoHover' style={{
                                                         "cursor": "pointer",
                                                         "marginLeft": '5px'
                                                     }} onClick={()=>{ this.del_user(row) }
                            }>删除</span> : null}
                        </>,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                {
                    "label_name": "用户名",
                    "type": "text",
                    "id": "search_username",
                    "value": '',
                    "must": false,
                    "nullable": true,
                },
            ],
            seachForm: {},
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_user();
    }

    get_user = (page=1, limit=10) => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/user/search', 'POST', {page, limit, filters: {...this.state.seachForm} }).then(res => {
            let data = res.data.records.filter(item => item.username !== 'root' )
            this.setState({"tableData": data, TotalRows: res.data.count})
            PubSub.publish('Loding');
        })
    }


    inquiry_user = (data) => {
        PubSub.publish('Loding', true);
        const DATA = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('search_')) {
                acc[key.replace('search_', '')] = data[key];
            }
            return acc;
        }, {});
        this.setState({seachForm: DATA}, ()=>this.get_user());
        PubSub.publish('Loding');
    }

    add_user = () => {
        this.setState({
            "modal_show": true,
            "modal_title": "新增用户",
            "modal_rules": [
                {
                    "label_name": "用户名",
                    "type": "text",
                    "id": "username",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入用户名",
                },
                {
                    "label_name": "密码",
                    "type": "password",
                    "id": "password",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入密码",
                },
                {
                    "label_name": "确认密码",
                    "type": "password",
                    "id": "password2",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "placeholder": "请再次输入密码",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                if (data.password === data.password2) {
                    delete data.password2
                    http_call('/api/biz/user/create', 'POST', data).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === 200) {
                            alert_info = {
                                "title": "提示",
                                "content": '操作成功！',
                                "type": "success",
                                "position": "top-center",
                                "dismiss": 3000,
                            }
                            this.setState({"modal_show": false})
                            this.get_user()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": '操作失败！',
                                "type": "error",
                                "position": "top-center",
                                "dismiss": '',
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                } else {
                    PubSub.publish('AlertNotify', {
                        "title": "提示",
                        "content": '两次输入密码不相同！',
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '',
                    });
                }
            },
        });
    }

    edit_user = (row) => { 
        this.setState({
            "modal_show": true,
            "modal_title": "修改用户",
            "modal_rules": [
                {
                    "label_name": "用户名",
                    "type": "text",
                    "id": "username",
                    "value": row.username,
                    "must": true,
                    "nullable": false,
                    "placeholder": "请输入用户名",
                },
                {
                    "label_name": "密码",
                    "type": "password",
                    "id": "password",
                    "value": '',
                    "must": false,
                    "nullable": true,
                    "placeholder": "请输入密码",
                },
                {
                    "label_name": "确认密码",
                    "type": "password",
                    "id": "password2",
                    "value": '',
                    "must": false,
                    "nullable": true,
                    "placeholder": "请再次输入密码",
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                if (data.password === data.password2) {
                    delete data.password2
                    let form = {id: row.id, username: data.username}
                    if (data.password) {
                        form.password = data.password
                    }
                    http_call('/api/biz/user/editor', 'POST', form).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === 200) {
                            alert_info = {
                                "title": "提示",
                                "content": '操作成功！',
                                "type": "success",
                                "position": "top-center",
                                "dismiss": 3000,
                            }
                            this.setState({"modal_show": false})
                            this.get_user()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": '操作失败！',
                                "type": "error",
                                "position": "top-center",
                                "dismiss": '',
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                } else {
                    PubSub.publish('AlertNotify', {
                        "title": "提示",
                        "content": '两次输入密码不相同！',
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '',
                    });
                }
            },
        });
    }

    del_user = async(row) =>{
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
        {
          "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
          "okText": "确认",
          okButtonStyle: 'warning',
          "cancelText": "取消"
        });
        if (result) {
            http_call('/api/biz/user/remove', 'POST', {id: row.id}).then(res=>{
                let alert_info;
                if (res.RTN_CODE === 200) {
                    alert_info = {
                        "title": "提示",
                        "content": '操作成功！',
                        "type": "success",
                        "position": "top-center",
                        "dismiss": 3000,
                    }
                    this.get_user();
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": '操作失败！',
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '',
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    
    render() {
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    {true ? <button className='btn btn-primary btn-xs'
                                                                   onClick={e => {
                                                                       this.add_user();
                                                                   }
                                                                   }>新增用户
                                    </button> : null}
                                    <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {
                                        this.inquiry_user(data)
                                    }} />
                                </div>
                                <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={this.state.TotalRows}
                                    paginationComponentOptions={{
                                        rowsPerPageText: '每页行数:',
                                        rangeSeparatorText: '/',
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: '全部'
                                    }}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight={"100vh"}
                                    dense={false}
                                    theme=""
                                    onChangeRowsPerPage={(newPerPage, page) => this.get_user(page, newPerPage)}
                                    onChangePage={(page) => this.get_user(page)}
                                    // onSelectedRowsChange={(rows) => {
                                    //     this.setState({"selectRows": rows})
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
