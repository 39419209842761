import React from "react";
import '../css/style.css';
import BootstrapTreeTable from 'bootstrap-react-treetable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from '../../../../config.js';
import './css/style.css';
import {api_call} from '../../../../libs/utils.js';
import PubSub from 'pubsub-js';
import Select from 'react-select';
import DataTable from 'react-data-table-component';




class SystemMenus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "系统菜单配置",
            "column": [
                {
                    "dataField": "name",
                    "heading": "名称",
                },
                {
                    "dataField": "link_id",
                    "heading": "ID",
                },
                {
                    "dataField": "link",
                    "heading": "地址"
                },
                {
                    "dataField": "owning_menu_id",
                    "heading": "上级ID"
                },
                {
                    "dataField": "sibling_sort",
                    "heading": "排序"
                },
                {
                    "dataField": "icon_class",
                    "heading": "图标"
                },
                {
                    "dataField": "show",
                    "heading": "是否展示"
                },
                {
                    "dataField": "",
                    "heading": "操作",
                    "renderer":  (dataRow, dataField) => {
                        if (dataRow.data.link === '') {
                            return <div>
                            <button style={{"marginRight": '5px'}} className='btn btn-warning btn-xs' onClick={e => {
                                this.show_edit_menu(e, dataRow.data.link_id)
                            }}>修改</button>
                            <button className='btn btn-danger btn-xs' onClick={e => {
                                this.del_menu(dataRow.data.link_id)
                            }}>删除</button>
                            </div>
                        }
                        else{
                            return <div><button style={{"marginRight": '5px'}} className='btn btn-warning btn-xs' onClick={e => {
                                this.show_edit_menu(e, dataRow.data.link_id)
                            }}>修改</button>
                            <button style={{"marginRight": '5px'}} className='btn btn-danger btn-xs' onClick={e => {
                                this.del_menu(dataRow.data.link_id)
                            }}>删除</button>
                            <button className='btn btn-success btn-xs' onClick={e => {
                                this.show_functoinmanagement_modal(e, dataRow.data)
                            }}>功能绑定</button>
                            </div>
                        }
                    }
                }
            ],
            show: [
                {value: 0, label: '不展示'},
                {value: 1, label: '展示'}
            ],
            "control": {
                "visibleRows": 1,
                "allowSorting": false,
                "showPagination": true,
                "initialRowsPerPage": 10,
                "allowFiltering": false,
                "showExpandCollapseButton": true
            },
            "show_add_menu": false,
            "all_menus": [],
            "menu_modal_data": {
                "name": '',
                "link_id": "",
                "link": "",
                "owning_menu_id": "",
                "sibling_sort": "",
                "icon_class": "",
                "callback": "",
                "modal_title": "",
                'show': ""
            },
            'show_functionmanagement_modal': false,
            'show_functionmanagement_modal_title': '',
            'functionmanagement_column': [
                {
                    "name": "ID",
                    "selector": row => row.functionid,
                },
                {
                    "name": "功能名称",
                    "selector": row => row.functionname,
                },
                {
                    "name": "描述",
                    "selector": row => row.description,
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <FontAwesomeIcon title='删除' icon="fa fa-trash" size='xl'
                                                style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                            this.del_menu_function(row.functionid, row.menu_id);
                        }}/>
                    },
                }

            ],
            'functionmanagement_data': [],
            'functionmanagement_select_data': [],
            'functionmanagement_selected_data': [],
        }
    }

    del_menu_function_callback = (res)=>{
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
        }
        api_call('/menus/get_menu_functions?link_id=-1', 'GET', this.set_functionmanagement_select_data, {},{"menu_id": this.state.current_menu_id})// 获取菜单下所有未分配的功能
        PubSub.publish('AlertNotify', alert_info);
    }
    del_menu_function = (functionid, menu_id)=>{
        api_call('/menus/del_menu_function', 'POST', this.del_menu_function_callback, {"functionid": functionid, 'menu_id': menu_id})
    }
     // 已经选中的接口
    set_funtionmanagement_selected_data = (res, data)=>{
        let OptionData = [];
        data.data.forEach((item)=>{
            OptionData.push({"value": item.functionid, "label": item.functionname, "menu_id": data.menu_id});
        })
        this.setState({"functionmanagement_select_data": OptionData, 'functionmanagement_data': res.DATA});
        this.setState({"show_functionmanagement_modal": true, "current_menu_id": data.menu_id})
    }

    set_functionmanagement_select_data = (res, data)=>{
        let OptionData = []     //获取所有selected data
        res.DATA.forEach((item)=>{
            OptionData.push({"value": item.functionid, "label": item.functionname})
        })
        api_call('/menus/get_menu_functions?link_id=' + data.menu_id, 'GET', this.set_funtionmanagement_selected_data,
            {}, {"data": res.DATA, "functionmanagement_select_data": OptionData, "menu_id": data.menu_id})
    }

    show_functoinmanagement_modal = (e, row)=>{
        api_call('/menus/get_menu_functions?link_id=-1', 'GET', this.set_functionmanagement_select_data,{},{"menu_id": row.link_id})// 获取菜单下所有未分配的功能
        this.setState({"show_functionmanagement_modal": true, "show_functionmanagement_modal_title": row.name, "pending": false});
    }

    set_tableData = (res) => {
        this.setState({"tableData": res.DATA});
    }
    componentDidMount() {
        api_call('/menus', 'GET', this.set_tableData)
    }
    set_all_menus = (res)=>{
        if (res.RTN_CODE === '01'){
            this.setState({"all_menus": res.DATA})
        }
    }
    del_menu_callback = res=>{
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
            api_call('/menus', 'GET', this.set_tableData)
        }
        else{
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        PubSub.publish('AlertNotify', alert_info);
    }
    del_menu = (id)=>{
        api_call('/menus/delete_menu', 'POST', this.del_menu_callback, {"link_id": id})
    }
    show_add_menu = (e)=>{
        api_call("/menus/get_root_menus", 'GET', this.set_all_menus)
        this.setState({"show_add_menu": true, "menu_modal_data":
                {
                    "modal_title": "新增菜单",
                    "button_name": "提交新增",
                    "callback": this.add_menu
                }})
    }

    set_menu_modal_data = (res) => {
        if (res.RTN_CODE === '01'){
            res.DATA[0]["callback"] = this.edit_menu;
            res.DATA[0]["modal_title"] = '修改菜单:' + res.DATA[0].name;
            res.DATA[0]["button_name"] = '提交修改';
            this.setState({"menu_modal_data": res.DATA[0], "show_add_menu": true})
        }
    }
    show_edit_menu = (e, link_id)=>{
        api_call("/menus/get_root_menus", 'GET', this.set_all_menus);
        api_call("/menus/get_menus_info", 'POST', this.set_menu_modal_data, {"link_id": link_id});
    }
    callback_edit_menu = res => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
            api_call('/menus', 'GET', this.set_tableData);
            this.hide_add_menu();
        }
        else{
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        PubSub.publish('AlertNotify', alert_info);
    }
    edit_menu = (e)=>{
        let link_id = this.state.menu_modal_data.link_id;
        let name = $("#name").val()
        let link = $("#link").val()
        let owning_menu_id = $("#owning_menu_id").val()
        let sibling_sort = $("#sibling_sort").val()
        let icon_class = $("#icon_class").val()
        let show = $("#show").val()
        let edit_data = {
            "link_id": link_id,
            "name": name,
            "link": link,
            "owning_menu_id": owning_menu_id,
            "sibling_sort": sibling_sort,
            "icon_class": icon_class,
            "show": parseInt(show),
        }
        api_call("/menus/update_menu", 'POST', this.callback_edit_menu, edit_data);
    }

    hide_add_menu = (e)=>{
        this.setState({"show_add_menu": false})
    }

    add_menu = (e) => {
        $(e.target).attr("disabled","disabled")
        let name = $("#name").val()
        let link = $("#link").val()
        let owning_menu_id = $("#owning_menu_id").val()
        let sibling_sort = $("#sibling_sort").val()
        let icon_class = $("#icon_class").val()
        let show = $("#show").val()
        let data = {"name": name, "link": link, "owning_menu_id": owning_menu_id, "sibling_sort": sibling_sort, "icon_class": icon_class, "show": parseInt(show)}
        fetch(config.api_url + "/menus/create_menu",{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('accessToken')
            },
            method: "POST",
            body: JSON.stringify(data)
        }).then(res=>{return res.json()})
        .then(res=>{
            $(e.target).removeAttr("disabled")
            let alert_info;
            if (res.RTN_CODE === '01') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-right",
                    "dismiss": 3000,
                }
                this.hide_add_menu();
            }
            else{
                alert_info = {
                    "title": "错误",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 3000,
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        })
        .catch(function(res){
            $(e.target).removeAttr("disabled")
            let alert_info = {
                "title": "出错了",
                "content": "服务器内部错误",
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
            PubSub.publish('AlertNotify', alert_info);
        })
    }

    function_select_change = (data)=>{
        this.setState({"functionmanagement_selected_data": data});
    }

    submit_bind_function = (e)=>{
        if (this.state.functionmanagement_selected_data.length === 0) return;
        $(e.target).attr("disabled", 'disabled')
        this.state.functionmanagement_selected_data.forEach(item=>{
            api_call('/menus/add_function', 'POST', this.submit_bind_callback, {"menu_id": this.state.current_menu_id, 'functionid': item.value}, {'e': e})
        })
    }

    submit_bind_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
        }
        $(data.e.target).removeAttr("disabled")
        // api_call('/menus/get_menu_functions', 'GET', this.set_functionmanagement_select_data,{},{"current_menu_id": this.state.current_menu_id})
        PubSub.publish('AlertNotify', alert_info);
        api_call('/menus/get_menu_functions?link_id=-1', 'GET', this.set_functionmanagement_select_data,{},{"menu_id": this.state.current_menu_id})// 获取菜单下所有未分配的功能
    }


    render() {
        return (
            <div className='page-inner' data-background-color={this.props.theme.body}>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa-brands fa-dev"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>开发配置</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                <button className='btn btn-primary btn-sm' onClick={e=>{this.show_add_menu(e)}}>新增</button>
                            </div>
                            <div className='table-responsive' >
                                {this.state.tableData !== undefined ?
                                    <BootstrapTreeTable columns={this.state.column} tableData={this.state.tableData}
                                                    control={this.state.control}/>
                                    : <h1 className='text-center'>暂无数据</h1>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  show={this.state.show_add_menu}
                >
                  <Modal.Header >
                    <Modal.Title>
                        {this.state.menu_modal_data.modal_title}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                        <div className="form-group form-floating-label">
                            <input type="text" id='name' placeholder='菜单名称' defaultValue={this.state.menu_modal_data.name} className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input  type="text" id='link' placeholder='菜单路径' defaultValue={this.state.menu_modal_data.link} className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <select className="selectpicker form-control input-border-bottom" id='owning_menu_id'>
                                <option value='0'>顶级菜单</option>
                                {
                                    this.state.all_menus.map(item => {
                                            if (item.link_id === this.state.menu_modal_data.owning_menu_id)
                                                return <option selected key={item.link_id} defaultValue={item.link_id} value={item.link_id}>{item.name}</option>
                                            return <option key={item.link_id} value={item.link_id}>{item.name}</option>
                                        }
                                    )
                                }
                            </select>
                        </div>
                        <div className="form-group form-floating-label">
                            <input  type="text" id='sibling_sort' placeholder='同级排序' defaultValue={this.state.menu_modal_data.sibling_sort} className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input  type="text" id='icon_class' placeholder='图标样式'  defaultValue={this.state.menu_modal_data.icon_class} className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input  type="text" id='show' placeholder='是否展示'  defaultValue={this.state.menu_modal_data.show} className="form-control input-border-bottom"/>
                        </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className='btn-danger' onClick={this.hide_add_menu} size='sm'>关闭</Button>
                    <Button onClick={e=>{this.state.menu_modal_data.callback(e)}} size='sm'>{this.state.menu_modal_data.button_name}</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.show_functionmanagement_modal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.show_funtionmanagement_modal_title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Select
                            options={this.state.functionmanagement_select_data}
                            isMulti
                            placeholder='请选择要绑定的功能'
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={e=>{this.function_select_change(e);}}
                            style={{"zIndex": '9999'}}
                          />
                        <DataTable
                                    columns={this.state.functionmanagement_column}
                                    data={this.state.functionmanagement_data}
                                    selectableRows
                                    progressPending={this.state.pending}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight={$(window).height() + 'px'}
                                    theme=""
                                />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-danger' size='sm' onClick={e=>{this.setState({"show_functionmanagement_modal": false})}}>关闭</Button>
                        <Button size='sm' onClick={e => {
                            this.submit_bind_function(e)
                        }}>绑定</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default SystemMenus;
