import React from 'react';
import './App.css';
import Header from './layout/header/header';
import MainContent from './layout/body/MainContent/MainContent';
import SideBar from './layout/body/SideBar/SideBar';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import NoFound404 from "./components/NoFound_404/NoFound_404";
import Login from './components/Login/Login';
import {is_token_expire} from './libs/utils.js';
import $ from 'jquery';
import AlertNotify from './components/AlertNotify/AlertNotify';
import PubSub from 'pubsub-js';
import Loading from './components/Loading/Loading'

library.add(fab, fas, far);


class App extends React.Component {
    constructor(props) {
        super(props);
        let init_view = window.location.pathname.replace('/', '');
        // let theme = new Date().getHours() > 7 || new Date().getHours() < 23 ? {
        let theme = new Date().getHours() > 23 || new Date().getHours() < 7 ? {
                "sidebar": "dark2",
                "logo": "dark2",
                "nav": "dark2",
                "body": "dark"
            } :
            {
                "sidebar": "",
                "logo": "blue",
                "nav": "blue",
                "body": ""
            }
        this.state = {
            "wrapStats": "", 
            "current_MainContent": init_view === '' ? "NoDevice" : init_view, "logo_toggle": false,//设置默认主页  SystemUsers
            "theme": theme,
            "page_status": '200',
            "show_alert_notify": false,
            "alert_info": {
                "title": "",
                "content": "",
                "type": "info",
                "position": "top-center",
                "dismiss": '',
                "callback": this.set_show_alert_notify
            },
            'expire_tid': null,
            "loading_info": {"show": false, "msg": ''},
            "component_data": {},
            "sidebar_ready": false
        }
        window.history.pushState('', '', window.location.origin + '/' + this.state.current_MainContent)
        if(theme.body !== '')
            $("body").attr({"data-background-color": theme.body})
    }


    set_sidebar_ready = (status)=>{
        this.setState({"sidebar_ready": status})
    }

    set_show_alert_notify = (status)=>{
        this.setState({"show_alert_notify": status})
    }

    subscrib_alert_notify = (topic, alert_info) => {
        this.setState({"show_alert_notify": true});
        alert_info['callback'] = this.set_show_alert_notify;
        this.setState({"alert_info": alert_info});
    }

    // subscrib_loading = (topic, msg) => {
        // this.setState({"show_alert_notify": true});
        // alert_info['callback'] = this.set_show_alert_notify;
        // this.setState({"alert_info": alert_info});
    //     console.log(topic, msg)
    // }

    subscrib_Loding = (topic, show=false, msg='') => {
        let status =  {"show": show, "msg": msg};
        this.setState({"loading_info": status})
    }


    set_page_status = (status) => {
        this.setState({"page_status": status})
    }

    async componentDidMount() {
        if (is_token_expire()){     //没有登录或者token失效
            if (window.location.pathname.replace('/', '') !== 'login')
            // window.localStorage.setItem('forward', window.location.pathname.replace('/', ''))
            this.set_page_status('403');
            return;
        }
        window.addEventListener('popstate', (event) => {        // 监听路由
            console.log(window.location.pathname.replace('/', ''));
            this.setState({"current_MainContent": window.location.pathname.replace('/', '')})
        })
        PubSub.subscribe('AlertNotify', this.subscrib_alert_notify);
        // loading
        PubSub.subscribe('Loding', this.subscrib_Loding);
        clearInterval(this.state.expire_tid)
        this.setState({
            expire_tid: setInterval(() => {
                is_token_expire()
            }, 60000)
        })
        // 权限获取移至menus.js
    }
    componentWillUnmount() {
        clearInterval(this.state.expire_tid)
    }

    wrapStats = (classNames) => {
        this.setState({wrapStats: classNames})
    }
    // , history=false
    set_current_MainContent =  (MainContentComponent, data) => {
        // console.log(window.location.pathname.replace('/', ''));
        if (is_token_expire()){     //没有登录或者token失效
            // window.localStorage.setItem('forward', window.location.pathname.replace('/', ''))
            this.set_page_status('403');
            return;
        }
        // 设置content页面
        this.setState({"component_data": data});
        this.setState({"current_MainContent": MainContentComponent});
        window.history.pushState('', '', window.location.origin + '/' + MainContentComponent)
    }

    set_logo_toggle = (status) => {
        this.setState({logo_toggle: status});
    }

    render() {
        if (this.state.page_status === '404') {
            return <NoFound404 theme={this.state.theme}/>
        } else if (this.state.page_status === '403' || this.state.current_MainContent === 'login') {
            if (!is_token_expire()){
                // window.localStorage.setItem('forward', window.location.pathname.replace('/', ''))
                window.location.href = '/'
                return
            }
            window.history.pushState('', '', window.location.origin + '/login')
            return <Login theme={this.state.theme}/>
        }
        else if (! is_token_expire()){     //没有登录或者token失效
            return (
                <div>
                     {this.state.loading_info.show ? <Loading loading_info={this.state.loading_info} /> : null}

                    {this.state.show_alert_notify ? <AlertNotify title={this.state.alert_info.title}
                                                                 content={this.state.alert_info.content}
                                                                 type={this.state.alert_info.type}
                                                                 position={this.state.alert_info.position}
                                                                 dismiss={this.state.alert_info.dismiss}
                                                                 callback={this.state.alert_info.callback}
                        /> :
                        null
                    }
                    <div className={this.state.wrapStats ? this.state.wrapStats : 'main-header'}
                         data-background-color={this.state.theme.body}>
                        <Header wrapStats={this.wrapStats}
                                set_current_MainContent={this.set_current_MainContent}
                                set_logo_toggle={this.set_logo_toggle}
                                logo_toggle={this.state.logo_toggle}
                                theme={this.state.theme}
                        />
                        <SideBar set_current_MainContent={this.set_current_MainContent}
                                 wrapStats={this.wrapStats}
                                 wrapClassName={this.state.wrapStats}
                                 set_logo_toggle={this.set_logo_toggle}
                                 theme={this.state.theme}
                                 set_sidebar_ready = {this.set_sidebar_ready}
                        />
                        {/* {this.state.sidebar_ready ? */}
                        <MainContent current_MainContent={this.state.current_MainContent}
                                set_current_MainContent={this.set_current_MainContent}
                                theme={this.state.theme}
                                set_status={this.set_page_status}
                                component_data={()=>{return this.state.component_data}}
                        />
                             {/* : null
                        } */}
                    </div>
                </div>
            )
        }
        else{
            return <Login theme={this.state.theme}/>
        }
    }
}

export default App;
