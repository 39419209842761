import React from 'react';
import "./css/style.css"

import 'element-theme-default';

class Loading2 extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: props.loading_info.show,
            msg: props.loading_info.msg
        }
    }
    render() {
    //     return (
    //       <div className="el-loading-demo">
    //         { this.state.fullscreen && <Loading className='bgcolor' fullscreen={true} /> }
    //         {/* <Loading text="拼命加载中" fullscreen={true} /> */}
    //       </div>
    //     )
        return (
          <div className="el-loading-demo">
            { this.state.fullscreen && 
           <div style={{'position': 'fixed', 'inset': '0px', 'zIndex': '99999'}}>
            <div className='bg bgcolor'>
            {/* <div style={{'display': 'block','position': 'absolute','z-index': '657','background-color': 'rgba(68, 65, 65, 0.5)','margin': '0px','inset':'0px'}}> */}
              <div className="el-loading-spinner is-full-screen" style={{'position': 'absolute', 'display': 'inlineBlock', 'left': '0px'}}>
                <svg className="circular" viewBox="25 25 50 50"><circle className="path" cx="50" cy="50" r="20" fill="none">
                  </circle>
                  </svg>
                  </div>
                  </div>
                  </div>}


          </div>
        )
      }

}

export default Loading2;