import React from "react";
import '../css/style.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './css/style.css';
import {api_call} from '../../../../libs/utils.js';
import PubSub from 'pubsub-js';
import DataTable from 'react-data-table-component';
import {confirm} from 'react-bootstrap-confirmation';


class ApiManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "接口管理",
            "column": [
                {
                    "name": "ID",
                    "selector": row => row.apiid,
                    "width":'100px' 
                },
                {
                    "name": "名称",
                    "selector": row => {
                        return <input value={row.apiname} onTouchEndCapture={e=>{console.log(e)}} readOnly defaultValue={row.apiname} onClick={e=>{this.input_handleClick(e, row, 'apiname')}} onBlur={e=>{this.input_blur(e, row, 'apiname')}} className="edit_input"/>
                    },
                },
                {
                    "name": "地址",
                    "selector": row => {
                        return <input readOnly value={row.apipath} defaultValue={row.apipath} onClick={e=>{this.input_handleClick(e, row, 'apipath')}} onBlur={e=>{this.input_blur(e, row, 'apipath')}} className="edit_input"/>
                    },
                    "width":'350px' 
                },
                {
                    "name": "接口描述",
                    "selector": row => {
                        return <input readOnly value={row.description} defaultValue={row.description} onClick={e=>{this.input_handleClick(e, row, 'description')}} onBlur={e=>{this.input_blur(e, row, 'description')}} className="edit_input"/>
                    },
                },
                {
                    "name": "调用方法",
                    "selector": row => {
                        return <div onClick={e=>{this.select_handleClick(e, row, 'method')}} onMouseOut={e=>{this.select_blur(e)}}>
                                <select disabled style={{"appearance": "none"}} className="td_select" onChange={e=>{this.select_change(e, row, 'method')}}>
                                    {this.state.methods.map((item, idx)=>{
                                        if(item === row.method)
                                            return <option key={idx} selected defaultValue={row.method}>{row.method}</option>
                                        return <option key={idx}>{item}</option>
                                    })}
                                </select>
                            </div>
                    },
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <FontAwesomeIcon icon="fa fa-trash" size='xl' style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                                this.del(row.apiid)
                            }}/>
                    }
                }
            ],
            "methods": ['未说明', 'GET', 'POST', 'DELETE', 'PUT'],
            "show_modal": false,
            "modal_title": "",
            "modal_submit_callback": "",
            "pending": true,
            "tableData": [],
        }
    }

    edit_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 1000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        api_call('/apimanagement', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }

    input_blur = (e, row, field)=>{
        $(e.target).removeClass('on_edit_input');$(e.target).attr('readonly', 'readonly');
        if ($(e.target).val().trim() !== row[field]){
            api_call('/apimanagement/update_api', 'POST', this.edit_callback, {"apiid": row.apiid, [field]: $(e.target).val().trim()})
        }
    }

    input_handleClick = (e, row, field) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                $(e.target).addClass('on_edit_input')
                $(e.target).removeAttr('readOnly')
                break;
            case 3:
                break;
            default:
                break
        }
    }

    select_blur = (e) =>{
        $(e.target).css('appearance', 'none');
        $(e.target).attr('disabled', 'disabled');
    }
    select_change = (e, row, field)=>{
        $(e.target).css('appearance', 'none');
        $(e.target).attr('disabled', 'disabled');
        if ($(e.target).val().trim() !== row[field]){
            api_call('/apimanagement/update_api', 'POST', this.edit_callback, {"apiid": row.apiid, [field]: $(e.target).val().trim()})
        }
    }
    select_handleClick = (e, row) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                $(e.target).removeAttr('disabled')
                $(e.target).css('appearance', '')
                break;
            case 3:
                break;
            default:
                break;
        }
    }

    set_tableData = (res) => {
        // const [TableData, SeTableData] = useState([])
        // this.state.SeTableData(res.DATA)
        this.setState({"tableData": res.DATA, "pending": false});
    }

    componentDidMount() {
        api_call('/apimanagement', 'GET', this.set_tableData)
    }

    close_modal = () => {
        this.setState({"show_modal": false})
    }

    show_add_modal = () => {
        this.setState({"show_modal": true, "modal_title": "新增接口", "modal_submit_callback": this.add});
    }

    add_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        $(data.e.target).removeAttr("disabled")
        api_call('/apimanagement', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }

    add = (e) => {
        $(e.target).attr("disabled", "disabled")
        let apiname = $("#apiname").val().trim();
        let apipath = $("#apipath").val().trim();
        let description = $("#description").val().trim();
        let method = $("#method").val().trim();
        let data = {"apiname": apiname, "apipath": apipath, "description": description, "method": method};
        api_call('/apimanagement', 'POST', this.add_callback, data, {'e': e});
    }


    del_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        api_call('/apimanagement', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }
    del = async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            api_call('/apimanagement/delete_api', 'POST', this.del_callback, {"apiid": id})
        }
    }


    search = e => {
        let search_field = ['apipath', 'apiname', 'method'];   //定义搜索字段
        let search_data = [];
        search_field.forEach((v, i)=>{
            let search_value = $("#search_" + v).val().trim();
            if (search_value !== '') {
                search_data.push(v + '=' + search_value);
            }
        })
        if (search_data.length > 0){
            api_call('/apimanagement?' + search_data.join('&'), 'GET', this.set_tableData, search_data)
        }
    }

    render() {
        return (
            <div className='page-inner' data-background-color={this.props.theme.body}>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa-brands fa-dev"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>开发配置</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div  className='row text-left'>
                                <div className='col-md-2'>
                                    <button className='btn btn-primary btn-sm' onClick={e => {
                                        this.show_add_modal(e)
                                    }}><FontAwesomeIcon icon="fa fa-solid fa-plus" size='xl' />
                                    </button>
                                </div>
                                <div className='col-md-2'>
                                    <input type="text" className="form-control form-control-search" id='search_apiname' placeholder="名称"/>
                                </div>
                                <div className='col-md-2'>
                                    <input type="text" className="form-control form-control-search" id="search_apipath" placeholder="地址"/>
                                </div>
                                <div className='col-md-2'>
                                    <input type="text" className="form-control form-control-search" id="search_method" placeholder="调用方法"/>
                                </div>
                                <div className='col-md-2 text-left'>
                                    <button className='btn btn-primary btn-sm' onClick={e => {
                                        this.search(e)
                                    }}><FontAwesomeIcon icon="fa fa-magnifying-glass" size='xl' />
                                    </button>
                                    <button className='btn btn-info btn-sm' onClick={e => {
                                        $("#search_apiname").val('')
                                        $("#search_apipath").val('')
                                        $("#search_method").val('')
                                        api_call('/apimanagement', 'GET', this.set_tableData)
                                    }}>清空搜索
                                    </button>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <DataTable
                                    columns={this.state.column}
                                    data={this.state.tableData}
                                    selectableRows
                                    progressPending={this.state.pending}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight={$(window).height() + 'px'}
                                    theme=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.show_modal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal_title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group form-floating-label">
                            <input type="text" id='apiname' placeholder='接口名称'
                                   className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input type="text" id='apipath' placeholder='接口路径：/api'
                                   className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input type="text" id='description' placeholder='接口描述'
                                   className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <select className="selectpicker form-control input-border-bottom" id='method'>
                                <option value='GET'>GET</option>
                                <option value='POST'>POST</option>
                                <option value='DELETE'>DELETE</option>
                                <option value='PUT'>PUT</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-danger' size='sm' onClick={this.close_modal}>关闭</Button>
                        <Button size='sm' onClick={e => {
                            this.state.modal_submit_callback(e)
                        }}>提交</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ApiManagement;
