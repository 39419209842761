import React from "react";
import $ from 'jquery';
import './css/style.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


class AlertNotify extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "close": false,
            "ico": {
                "warning": ["fa-solid fa-exclamation", "fa-shake", "col-10 col-xs-11 col-sm-4 alert alert-warning " + this.props.position, 'red'],
                "info": ["fa fa-circle-info", "fa-bounce", 'col-10 col-xs-11 col-sm-4 alert alert-primary ' + this.props.position, '#1572e8'],
                "success": ["fa fa-check", "fa-beat-fade", 'col-10 col-xs-11 col-sm-4 alert alert-success ' + this.props.position, 'green'],
                "danger": ['fa fa-bolt', "fa-shake", "col-10 col-xs-11 col-sm-4 alert alert-danger " + this.props.position, 'red'],
                "error": ['fa fa-triangle-exclamation', "fa-beat-fade", "col-10 col-xs-11 col-sm-4 alert alert-danger " + this.props.position, 'red']
            },
        }

    }
    componentDidMount() {
        if (this.props.dismiss){
            setTimeout(()=>{
                $("div[data-notify=container]").fadeTo(100,0)
                setTimeout(()=> {
                 this.setState({"close": true});
                 this.props.callback(false);
                }, 1000)
            }, this.props.dismiss)
        }
    }

    close = (e) => {
        $($($(e.target)).parents()[0]).fadeTo(100,0)
        setTimeout(()=>{
            this.setState({"close": true});
            this.props.callback(false);
        }, 500)
    }

    render() {
        if (this.state.close){
            return null;
        }
        return (
                <div data-notify="container" style={{"zIndex": "1060"}} className={this.props.type ? this.state.ico[this.props.type][2] : 'col-10 col-xs-11 col-sm-4 alert alert-secondary top-center' } role="alert">
                        <span onClick={e => {
                            this.close(e)
                        }} type="button" aria-hidden="true" className="close" data-notify="dismiss"
                                style={{"position": "absolute", "right": "5px", "top": "5px", "zIndex": "1060", "fontWeight": "bold", "color": "black"}}>×
                        </span>
                        <div className='row' style={{"marginLeft": 0}}>
                            <div className='col-md-1 col-2 col-xs-2 col-sm-2' style={{"marginTop": "10px"}}>
                                <FontAwesomeIcon icon={this.props.type ? this.state.ico[this.props.type][0] : 'fa fa-bell' } className=
                                    {this.props.type ? this.state.ico[this.props.type][1] : 'fa-beat-fade' } size='2xl'
                                                 style={{"paddingRight": '5px', "color": this.state.ico[this.props.type][3]}}/>
                            </div>
                            <div className='col-md-11 col-10 col-xs-10 col-sm-10'>
                            <span data-notify="title">{this.props.title}</span>
                            <span data-notify="message" style={{"color": this.state.ico[this.props.type][3]}}>{this.props.content}</span>
                            </div>
                        </div>
                    </div>
        )

    }
}

export default AlertNotify;