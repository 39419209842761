import React from "react";
import Logo from './Logo/Logo';
import Nav from './Nav/Nav';
import config from '../../config.js';


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "logoText": config.logoText || 'Longmiaohao'
        }
    }
    render() {
        return (
            <div>
                <Logo text={this.state.logoText}
                      wrapStats={this.props.wrapStats}
                      set_logo_toggle={this.props.set_logo_toggle}
                      logo_toggle={this.props.logo_toggle}
                      theme={this.props.theme}
                />
                {/* <Nav theme={this.props.theme}/> */}
            </div>
        )

    }
}

export default Header;