import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/icons-material/Link";


import { ArrowRight, Delete } from "@mui/icons-material";
import { useDragOver } from "@minoru/react-dnd-treeview";
import { TypeIcon } from "./TypeIcon";
import styles from "./CustomNode.module.css";

export const CustomNode = (props) => {
  const [hover, setHover] = useState(false);
  const { id, droppable, data, jb } = props.node;
  const indent = props.depth * 24;

  const [visibleInput, setVisibleInput] = useState(false);
  // const [labelText, setLabelText] = useState(text);

  // 打开展示数据的icon
  // const handleToggle = (e) => {
  //   e.stopPropagation();
  //   props.onToggle(props.node.id);
  // };
  async function handleToggle(e) {
    e.stopPropagation();
    await props.onToggle(props.node.id);
  }

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  return (
    <div
      onClick={handleToggle}
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ""
        }`}
      >
        {props.node.droppable && (
          <div>
            <ArrowRight />
          </div>
        )}
      </div>
      <div style={{'cursor': 'pointer', 'display': 'flex', 'alignItems': 'center'}} item-id={props.node.id} onClick={(e)=>{ props.onClickList(props.node) }}>
        <div style={{'pointerEvents': 'none'}}>
          <TypeIcon jb={jb} droppable={droppable} fileType={data?.fileType} />
        </div>
        { !visibleInput ? ( <div style={{'pointerEvents': 'none'}} className={styles.labelGridItem}>
          <Typography variant="body2" 
              >{props.node.text }</Typography>
        </div>):('')}
      </div>
      {/*  <div className={styles.labelGridItem}>
         <Typography variant="body2">{props.node.text}</Typography>
       </div> */}
      {hover && 
      (
        <>
          <div className={styles.actionButton}>
            { !props.isNoCaozuo ? <IconButton title="编辑" className={styles.editButton} onClick={(e) => {props.onEditor(id, props.node)}}>
            {/* { props.func.edit ? <IconButton className={styles.editButton} onClick={(e) => {props.onEditor(id, props.node)}}> */}
              <EditIcon className={styles.editIcon} />
            </IconButton> : null }
          </div>

          {/* 子项添加子项 */}
          { !props.isNoCaozuo ? <div className={styles.actionButton}>
            <IconButton title='添加子项' onClick={(node) => {props.onAddChild(id,node)}}>
              <AddIcon  />
            </IconButton>
          </div> : null}

          { false ? <IconButton title="绑定表具" size="small" onClick={(node) => {props.onLink(props.node)}}>
              <Link fontSize="small" />
            </IconButton> : null }

          { !props.isNoCaozuo ? <IconButton title="删除" size="small" onClick={() => {props.onDelete(id)}}>
          {/* { props.func.del ? <IconButton size="small" onClick={() => {props.onDelete(id)}}> */}
              <Delete fontSize="small" />
            </IconButton> : null }
        </>
      )}
    </div>
  );
};
