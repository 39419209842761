import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call} from '../../../../../libs/utils';
import PubSub from 'pubsub-js';
import TreeView from '../../../../../components/TreeView/TreeView2'


export default class Department extends Component {
    constructor(props) {
        super(props);
        let tt = new Date()
        let nowMonth = `${tt.getFullYear()}-${('0'+(tt.getMonth()+1)).slice(-2)}`
        this.state = {
            // "func": func,
            name: "部门管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "电话",
                    "selector": row => row.telephone,
                    center: true,
                },
                {
                    "name": "所属部门",
                    "selector": row => row.department,
                    center: true,
                },
                {
                    "name": "金额",
                    "selector": row => row.amount,
                    center: true,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                {
                    "label_name": "月份",
                    "type": "month",
                    "id": "search_date",
                    "value": nowMonth,
                    "must": false,
                    "nullable": true,
                },
            ],
            seachForm: {},
            nowMonth: nowMonth,
            selectRows: {},
            data: []
        }
    }

    componentDidMount() {
        this.get_report();
    }

    get_report = () => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/organization/search', 'POST', {page: 1, limit: 10000, sorter: {column: "id", asc: "true"}}).then(res => {
            res.data.records.map((item => {
                item.droppable = true
                item.text = item.title
                item.jb = 1 //组织
                item.parent = item.pid ? item.pid : 0;
                return 0
            }))
            this.setState({"data": res.data.records, TotalRows: res.data.count})
            PubSub.publish('Loding');
        })
    }

    searchDC = () => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/statistics/telephone-bill-export', 'POST', {date: this.state.nowMonth, ...this.state.seachForm}).then(res => {
            // this.setState({"tableData": res.DATA, TotalRows: res.COUNT})
            res.blob().then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'example.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            PubSub.publish('Loding');
        })
    }

    inquiry_report = (data) => {
        PubSub.publish('Loding', true);
        const DATA = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('search_')) {
                acc[key.replace('search_', '')] = data[key];
            }
            return acc;
        }, {});
        this.setState({seachForm: DATA}, ()=>this.get_report());
        PubSub.publish('Loding');
    }

    add_tree = (row) => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/organization/create', 'POST', {pid: row.pid, title: row.zzmc}).then(res => {
            // console.log(res);
            this.msg_alert(res)
            PubSub.publish('Loding');
        })
    }

    add_child_tree = (row) => {
        PubSub.publish('Loding', true);
        delete row.jb
        http_call('/api/zzgl/add_zz', 'POST', row).then(res => {
                // console.log(res);
                //   this.msg_alert(res)
            PubSub.publish('Loding');
        })
    }

    del_tree = (id) => {
        PubSub.publish('Loding', true);
        http_call('/api/biz/organization/remove', 'POST', {id: id}).then(res => {
            this.msg_alert(res)
            PubSub.publish('Loding');
        })
    }

    editor_zuzhi = (row) =>{
        PubSub.publish('Loding', true);
        http_call('/api/biz/organization/editor', 'POST', {id: row.id, pid: row.pid, title: row.zzmc}).then(res => {
            this.msg_alert(res)
            PubSub.publish('Loding');
        })
    }

    msg_alert =(res) =>{
        let alert_info;
        if (res.RTN_CODE === 200) {
            alert_info = {
                "title": "提示",
                "content": '操作成功！',
                "type": "success",
                "position": "top-center",
                "dismiss": 3000,
            }
            this.setState({'addDialog': false}, ()=> this.get_report())
        } else {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-center",
                "dismiss": '',
            }
        }
        PubSub.publish('AlertNotify', alert_info);
    }
    
    
    render() {
        const { data } = this.state
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card' style={{'display': 'flex','overflow': 'auto', 'flexDirection': 'row',"height": `${(document.body.clientHeight-135)}px`}}>
                                {/* <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    {false ? <button className='btn btn-primary btn-xs'
                                                                   onClick={e => {
                                                                       this.add_gs(e);
                                                                   }
                                                                   }>新增公司
                                    </button> : null}
                                    <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {
                                        this.inquiry_report(data)
                                    }} other_op={()=>{
                                        return <>
                                            <button className='btn btn-primary btn-sm' onClick={e => { this.searchDC() }}>导出 </button>
                                        </>
                                    }}
                                    />
                                </div> */}
                                { data.length ? 
                                    <div style={{'overflow': 'auto', 'height': '90%'}}>
                                        <TreeView
                                            onEdit = {(id, value)=>{
                                                this.editor_zuzhi(value)
                                                return true
                                            }}
                                            onDelete = {(id)=>{
                                                this.del_tree(id)
                                                return true
                                            }}
                                            onAdd = {(newNode)=>{
                                                this.add_tree(newNode)
                                                return true
                                            }}
                                            onCanceAdd = {()=>{
                                                this.setState({addDialog: false})
                                                return true
                                            }}
                                            onAddChild = {(newNode)=>{
                                                // this.add_child_tree(newNode)
                                                return true
                                            }}
                                            onClickList = {(node)=>{
                                                // console.log(node);
                                                // this.setState({clickNode: node}, ()=>this.onClickListTree())
                                                return true
                                            }}
                                            data={data}
                                            // addDialog={addDialog}
                                            // isSearch = {isSearch}
                                        />
                                    </div>
                                :
                                <h3 style={{'margin': '20px 0 0 20px'}}>暂无数据</h3>
                            }
                                {/* <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    // pagination
                                    // paginationServer
                                    // paginationTotalRows={this.state.TotalRows}
                                    // paginationComponentOptions={{
                                    //     rowsPerPageText: '每页行数:',
                                    //     rangeSeparatorText: '/',
                                    //     selectAllRowsItem: true,
                                    //     selectAllRowsItemText: '全部'
                                    // }}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight={"100vh"}
                                    dense={false}
                                    theme=""
                                    // onChangeRowsPerPage={(newPerPage, page) => this.get_report(page, newPerPage)}
                                    // onChangePage={(page) => this.get_report(page)}
                                    // onSelectedRowsChange={(rows) => {
                                    //     this.setState({"selectRows": rows})
                                    // }}
                                /> */}
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
