import React from "react";
import DataTable from 'react-data-table-component';
import $ from 'jquery';
// import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import PubSub from 'pubsub-js';
import Button from 'react-bootstrap/Button';
import PrivilegesGrant from '../../../../components/PrivilegesGrant/PrivilegesGrant';
import {http_call} from '../../../../libs/utils';
import {confirm} from 'react-bootstrap-confirmation';


class SystemRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "系统角色管理",
            "column": [
                {
                    "name": "ID",
                    "selector": row => row.roleid,
                },
                {
                    "name": "名称",
                    "selector": row => {
                        let edit_id = 'rolename'
                        return <input readOnly defaultValue={row.rolename} onClick={e => {
                            this.input_handleClick(e, row, edit_id)
                        }} onBlur={e => {
                            this.input_blur(e, row, edit_id)
                        }} className="edit_input"/>
                    },
                },
                {
                    "name": "创建用户",
                    "selector": row => {
                        return <input readOnly defaultValue={row.create_username} className="edit_input"/>
                    },
                },
                {
                    "name": "创建时间",
                    "selector": row => {
                        return <input readOnly defaultValue={row.createtime} className="edit_input"/>
                    },
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <div><FontAwesomeIcon title='删除' icon="fa fa-trash" size='xl'
                                                     style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                            this.del(row.roleid)
                        }}/>
                            <FontAwesomeIcon title='权限编辑' icon="fa fa-lock" style={{'color': 'green', "cursor": "pointer", "marginLeft": '5px'}}
                             size='xl' onClick={e=>{
                                 this.role_privilege_modal_show(row.roleid, row.rolename)
                            }} />
                        </div>
                    }
                }
            ],
            "grant_column": [
                {
                    "name": "ID",
                    "selector": row => row.roleid,
                },
                {
                    "name": "名称",
                    "selector": row => {
                        let edit_id = 'rolename'
                        return <input readOnly defaultValue={row.rolename} onClick={e => {
                            this.input_handleClick(e, row, edit_id)
                        }} onBlur={e => {
                            this.input_blur(e, row, edit_id)
                        }} className="edit_input"/>
                    },
                },
                {
                    "name": "创建用户",
                    "selector": row => {
                        return <input readOnly defaultValue={row.createuserid} className="edit_input"/>
                    },
                },
                {
                    "name": "创建时间",
                    "selector": row => {
                        return <input readOnly defaultValue={row.createtime} className="edit_input"/>
                    },
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <div><FontAwesomeIcon title='删除' icon="fa fa-trash" size='xl'
                                                     style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                            this.del(row.functionid)
                        }}/>
                        </div>
                    }
                }
            ],
            "grant_tableData": [],
            "role_modal_show": false,
            "role_modal_title": '新增角色',
            "pending": false,
            "current_roleid": '',
        }
    }

    // 编辑权限
    role_privilege_modal_show = (roleid, rolename)=>{
        this.setState({"role_privilege_modal_show": true, "role_privilege_modal_title": rolename + ' 权限编辑', "current_roleid": roleid})
    }
    del = async (roleid)=>{
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/roles/del_role', 'POST', {"roleid": roleid}).then((res) => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
                this.get_data()
            }).catch((res) => {
                console.log(res)
            })
        }
    }
    input_blur = (e, row, field)=>{
        $(e.target).removeClass('on_edit_input');$(e.target).attr('readonly', 'readonly');
        if ($(e.target).val().trim() !== row[field]){
            http_call('/roles/update_role', 'POST', {"roleid": row.roleid, [field]: $(e.target).val().trim()}).then((res) => {
                let alert_info;
                if (res.RTN_CODE === '01'){
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                else{
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            }).catch((res) => {
                console.log(res)
            })
        }
    }

    input_handleClick = (e, row, field) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                $(e.target).addClass('on_edit_input')
                $(e.target).removeAttr('readOnly')
                break;
            case 3:
                break;
            default:
                break
        }
    }

    get_data = ()=>{
        http_call('/roles', 'GET').then((res) => {
            this.setState({"tableData": res.DATA})
        }).catch((res) => {
            console.log(res)
        })
    }
    componentDidMount() {
        this.get_data()
    }

    setPrivilegesData = (data) => {
        this.setState({"checked_func_privileges": data})
    }

    create_role = (e) => {
        let role_name = $("#role_name").val()
        if (role_name === '') {
            let alert_info = {
                "title": "提示",
                "content": '角色名称不可为空',
                "type": "warning",
                "position": "top-right",
                "dismiss": 1000,
            }
            PubSub.publish('AlertNotify', alert_info);
            return
        }
        if (this.state.checked_func_privileges === undefined) {
            let alert_info = {
                "title": "提示",
                "content": '请勾选该角色的权限',
                "type": "warning",
                "position": "top-right",
                "dismiss": 1000,
            }
            PubSub.publish('AlertNotify', alert_info);
            return
        }
        http_call('/roles/create_role', 'POST', {
            "role_name": role_name,
            "privileges": this.state.checked_func_privileges
        }).then((res) => {
            let alert_info;
            if (res.RTN_CODE === '01') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-center",
                    "dismiss": 3000,
                }
                this.setState({"role_modal_show": false})
            } else {
                alert_info = {
                    "title": "错误",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-center",
                    "dismiss": 1000,
                }
            }

            this.get_data()
            PubSub.publish('AlertNotify', alert_info);
        }).catch((res) => {
            console.log(res)
        })
    }

    render() {
        return <div className='page-inner' data-background-color={this.props.theme.body}>
            <div className="page-header">
                <h4 className="page-title">{this.state.name}</h4>
                <ul className="breadcrumbs">
                    <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-gear"/>
                            </span>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                    </li>
                    <li className="nav-item">
                        <span>系统设置</span>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                    </li>
                    <li className="nav-item">
                        <span>{this.state.name}</span>
                    </li>
                </ul>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                            <button className='btn btn-primary btn-sm'
                                    onClick={e => {
                                        this.setState({"role_modal_show": true});
                                    }
                                    }>新增角色
                            </button>
                        </div>
                        <DataTable
                            noDataComponent=<h1 style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                        columns={this.state.column}
                        data={this.state.tableData}
                        selectableRows
                        progressPending={this.state.pending}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight={$(window).height() + 'px'}
                        theme=""
                        />
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.role_modal_show}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.state.role_modal_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group form-floating-label">
                        <input type="text" id='role_name' placeholder='角色名称'
                               className="form-control input-border-bottom"/>
                    </div>
                    <PrivilegesGrant setData={this.setPrivilegesData}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger'
                            onClick={e => {
                                this.setState({"role_modal_show": false});
                            }
                            }
                            size='sm'>关闭</Button>
                    <Button onClick={e => {
                        this.create_role(e)
                    }} size='sm'>提交</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.role_privilege_modal_show}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.state.role_privilege_modal_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrivilegesGrant setData={this.setPrivilegesData} current_roleid={this.state.current_roleid}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger'
                            onClick={e => {
                                this.setState({"role_privilege_modal_show": false});
                            }
                            }
                            size='sm'>关闭</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default SystemRole;