import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call} from '../../../../../libs/utils';
import PubSub from 'pubsub-js';
import $ from 'jquery';
import { Cascader } from 'antd';





export default class DepartmentReport extends Component {
    constructor(props) {
        // let func = {"add": 103, 'edit': 104, 'get': 105, 'chongzhi': 106, 'selectchonglog': 107, 'del': 131}
        // func = func_privileges(func)
        super(props);
        let tt = new Date()
        let nowMonth = `${tt.getFullYear()}-${('0'+(tt.getMonth()+1)).slice(-2)}`
        this.state = {
            // "func": func,
            name: "部门报表",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "月份",
                    "selector": row => row.useMonth,
                    center: true,
                },
                {
                    "name": "部门名称",
                    "selector": row => row.department,
                    center: true,
                },
                {
                    "name": "金额（元）",
                    "selector": row => row.amount,
                    center: true,
                },
                {
                    "name": "操作",
                    center: true,
                    "selector": row =>
                        <>
                            {true ? <span className='caozuoHover' style={{
                                                         "cursor": "pointer",
                                                     }} onClick={()=>{this.setState({rowDepart:  row}, ()=>{this.view_device(1,10)});}
                            }>查看</span>: null}
                        </>,
                },
            ],
            TotalRows: 0,
            "search_rule": [
                {
                    "label_name": "起始月份",
                    "type": "month",
                    "id": "search_begin",
                    "value": '',
                    "must": true,
                    "nullable": false,
                },
                {
                    "label_name": "截止月份",
                    "type": "month",
                    "id": "search_end",
                    "value": '',
                    "must": true,
                    "nullable": false,
                },
            ],
            seachForm: {},
            nowMonth: nowMonth,
            selectRows: {},
            selectDepat: '',
            selectOption: [],
            rowDepart: {}
        }
    }

    componentDidMount() {
        // this.get_report();
        this.get_option();
    }

    get_report = () => {
        const { seachForm, selectDepat } = this.state
        if ( seachForm.begin && seachForm.end) {
            PubSub.publish('Loding', true);
            http_call('/api/biz/statistics/department-bill', 'POST', {scope: seachForm, sorter: {column: "o.id"}, orgId: selectDepat}).then(res => {
                this.setState({"tableData": res.data})
                PubSub.publish('Loding');
            })
        } else {
            PubSub.publish('AlertNotify', {
                "title": "提示",
                "content": '请填写起止月和部门进行搜索',
                "type": "error",
                "position": "top-center",
                "dismiss": 3000,
            });
        }
    }

    get_option = () => {
        http_call('/api/biz/organization/option', 'POST', {sorter: {column: "orgId"}}).then(res => {
            this.setState({"selectOption": res.data})
            this.setState({"search_rule": [
                ...this.state.search_rule,
                {
                    "label_name": "所属部门",
                    "type": "text",
                    "id": "search_telephone",
                    "value": '',
                    "must": false,
                    "nullable": true,
                    "callback": ()=>{
                        return <div style={{height: '100%', width: '25%', padding: '10px'}}>
                            所属部门
                            <Cascader changeOnSelect={true}
                                showSearch={true} 
                                bordered={false} 
                                style={{borderBottom: '1px solid #d7d6d6', width: '70%'}} 
                                options={res.data} 
                                onChange={(value)=>{this.setState({selectDepat: value?value[value.length-1]:''})}} 
                                placeholder="搜索部门" 
                            />
                        </div>
                    }
                },
            ]})
        })
    }

    // 清空select框
    clearAll = () => {
        this.setState({selectDepat: ''})
        // $(".ant-select-selection-item").attr("class", "ant-select-selection-placeholder");
        // $(".ant-select-selection-placeholder").innerText = '搜索部门'
        if (($(".ant-select-selection-item")[0])) {
            $(".ant-select-selection-item")[0].innerText = '搜索部门'
        }
    }

    view_device = (page=1, limit=10) => {
        const { rowDepart } = this.state
        let title = rowDepart.department
        PubSub.publish('Loding', true);
        http_call('/api/biz/call-bill/search', 'POST', {page, limit,orgId: rowDepart.orgId, date: rowDepart.useMonth}).then(res => {
            // this.setState({"tableData": res.data})
            PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": title,
                "modal_rules": [
                    {
                        "label_name": "",
                        "type": "text",
                        "id": "",
                        "value": '',
                        "must": false,
                        "nullable": true,
                        "callback": ()=>{
                            return <DataTable
                                        style={{display: 'relative', }}
                                        noDataComponent={<h1 style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                        columns={[
                                            { name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> },
                                            {
                                                "name": "电话",
                                                "selector": row => row.telephone,
                                                center: true,
                                            },
                                            {
                                                "name": "金额（元）",
                                                "selector": row => (row.fee/100).toFixed(2),
                                                center: true,
                                            },
                                        ]}
                                        data={res.data.records}
                                        selectableRows={false}
                                        progressPending={this.state.pending}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={res.data.count}
                                        paginationComponentOptions={{
                                            rowsPerPageText: '每页行数:',
                                            rangeSeparatorText: '/',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: '全部'
                                        }}
                                        fixedHeader
                                        fixedHeaderScrollHeight={"100vh"}
                                        dense={false}
                                        theme=""
                                        onChangeRowsPerPage={(newPerPage, page) => this.get_report(page, newPerPage)}
                                        onChangePage={(page) => this.get_report(page)}
                                    />
                        }
                    },
                ],
                "modal_submit_title": "",
                "modal_submit_callback": (e, data) => {
                },
            });

        })
    }

    searchDC = () => {
        const { seachForm, selectDepat } = this.state
        if ( seachForm.begin && seachForm.end) {
            PubSub.publish('Loding', true);
            fetch("/api/biz/statistics/department-bill-export", {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + window.localStorage.getItem('accessToken')
                },
                body: JSON.stringify({scope: seachForm, sorter: {column: "o.id"}, orgId: selectDepat})
            }).then(response => {
                response.blob().then(res => {
                    const blob = new Blob([res], {type: "application/vnd.ms-excel"})
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = '部门报表.xlsx';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                })
                PubSub.publish('Loding');
            })
        } else {
            PubSub.publish('AlertNotify', {
                "title": "提示",
                "content": '请填写起止月和部门进行搜索',
                "type": "error",
                "position": "top-center",
                "dismiss": 3000,
            });
        }
    }

    inquiry_report = (data) => {
        PubSub.publish('Loding', true);
        const DATA = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('search_')) {
                acc[key.replace('search_', '')] = data[key];
            }
            return acc;
        }, {});
        this.setState({seachForm: DATA}, ()=>this.get_report());
        PubSub.publish('Loding');
    }
    
    
    
    render() {
        
        // const props = {
        //     name: 'file',
        //     action: '/api/biz/telephone/link',
        //     headers: {
        //         authorization: "Bearer "+  window.localStorage.getItem('accessToken'),
        //     },
        //     onChange(info) {
        //         if (info.file.status !== 'uploading') {
        //         console.log(info.file, info.fileList);
        //         }
        //         if (info.file.status === 'done') {
        //         message.success(`${info.file.name} file uploaded successfully`);
        //         } else if (info.file.status === 'error') {
        //         message.error(`${info.file.name} file upload failed.`);
        //         }
        //     },
        //     };
        return (
            <>
                <div className='page-inner'>
                    <div className="page-header">
                        <h4 className="page-title">{this.state.name}</h4>
                        <ul className="breadcrumbs">
                            <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>报表管理</span>
                            </li>
                            <li className="separator">
                                <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                            </li>
                            <li className="nav-item">
                                <span>{this.state.name}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                    {false ? <button className='btn btn-primary btn-xs'
                                                                   onClick={e => {
                                                                       this.add_gs(e);
                                                                   }
                                                                   }>新增公司
                                    </button> : null}
                                    <TableSearchFields clearAll={()=>{this.clearAll()}} rule={this.state.search_rule} onSearch={(data) => {
                                        this.inquiry_report(data)
                                    }} other_op={()=>{
                                        return <>
                                            {/* <Upload {...props}>
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload> */}
                                            <button className='btn btn-primary btn-sm' onClick={e => { this.searchDC() }}>导出 </button>
                                        </>
                                    }}
                                    />
                                </div>
                                <DataTable
                                    noDataComponent={<h1
                                        style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                                    columns={[{ name: '序号', center: true,width: '80px', cell: (row, index) => <div>{index + 1}</div> }, ...this.state.column]}
                                    data={this.state.tableData}
                                    selectableRows={false}
                                    progressPending={this.state.pending}
                                    // pagination
                                    // paginationServer
                                    // paginationTotalRows={this.state.TotalRows}
                                    // paginationComponentOptions={{
                                    //     rowsPerPageText: '每页行数:',
                                    //     rangeSeparatorText: '/',
                                    //     selectAllRowsItem: true,
                                    //     selectAllRowsItemText: '全部'
                                    // }}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight={"100vh"}
                                    dense={false}
                                    theme=""
                                    // onChangeRowsPerPage={(newPerPage, page) => this.get_report(page, newPerPage)}
                                    // onChangePage={(page) => this.get_report(page)}
                                    // onSelectedRowsChange={(rows) => {
                                    //     this.setState({"selectRows": rows})
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.modal_show ?
                        <DynamicModal show={this.state.modal_show}
                                      rules={this.state.modal_rules}
                                      title={this.state.modal_title}
                                      submit_title={this.state.modal_submit_title}
                                      close_callback={e => {
                                          this.setState({"modal_show": false})
                                      }}
                                      submit_callback={this.state.modal_submit_callback}
                        />
                        : null
                    }
                </div>
            </>
        );
    }
}
