import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline } from "@mui/material";
import {
  Tree,
  MultiBackend,
  getDescendants,
  getBackendOptions
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { AddDialog } from "./AddDialog";
import { theme } from "./theme.js";
import styles from "./App.module.css";
import {confirm} from 'react-bootstrap-confirmation';

// import SampleData from "./sample_data.json";


const getLastId = (treeData) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }
    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};

function TreeView2(props) {
  let initialOpenTmp = JSON.parse(localStorage.getItem('initialOpenTmp'))
  useEffect(() => {
    setTreeData(props.data);
    setOpen(props.addDialog);
  }, [props]);
  const ref = useRef(null);


  const [treeData, setTreeData] = useState(props.data);
  const [open, setOpen] = useState(props.addDialog);
  // 搜索的内容
  // const [serachValue, setSerachValue] = useState("");
  const [add_node, setAddcChild] = useState("");
  const [editor_zz, setEditorZhuzhi] = useState("");
  const handleDrop = (newTree) => setTreeData(newTree);

  // 删除树
  const handleDelete = async (id, handler) => {
    // console.log(handler);
    // 父组件调用该删除接口时触发
    const result = await confirm(<h2 className='text-center text-danger'>确认删除此节点?</h2>,
    {
      "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
      "okText": "确认",
      okButtonStyle: 'warning',
      "cancelText": "取消"
    });
    if (result) {
        if (handler(id)) {
        // const deleteIds = [
        //   id,
        //   ...getDescendants(treeData, id).map((node) => node.id)
        // ];
        // const newTree = treeData.filter((node) => !deleteIds.includes(node.id));
        // console.log("删除");
        setTreeData(props.data);
      }
    }
  };

  // 复制树
    const handleCopy = (id,handler) => {
      if (handler(id)) {
        const lastId = getLastId(treeData);
        const targetNode = treeData.find((n) => n.id === id);
        const descendants = getDescendants(treeData, id);
        const partialTree = descendants.map((node) => ({
          ...node,
          id: node.id + lastId,
          parent: node.parent + lastId
        }));
    
        setTreeData([
          ...treeData,
          {
            ...targetNode,
            id: targetNode.id + lastId
          },
          ...partialTree
        ]);
      }
    };

  // 修改树
  const handleEditor = (id, value, handler) => {
    // console.log(id, value)
    handleOpenDialog()
    if (handler(id, value)){  //父组件 调用接口成功
      setOpen(false);
      // setEditorZhuzhi(value)
    }
  };

  // 新增子树
  const handleAddChild = (id, value, handler) => {
    handleOpenDialog()
    if (handler(id, value)){  //父组件 调用接口成功
      // console.log(id, value);
      setAddcChild(id)
    }
  };

  // 点击子树
  const onClickListTree = (id, handler) => {
    if (handler(id)){  //父组件 调用接口成功
      // console.log(id, value);
    }
  };

  // 点击搜索按钮的时候
  // const handleSerach = ()=>{
  //   // console.log(serachValue);
  // }
  // 实现搜索栏的双向绑定
  // const handleChangeText = (e) => {
  //   setSerachValue(e);
  // };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setAddcChild(0)
    setEditorZhuzhi(0)
  };

  // 新增树
  const handleSubmit = (newNode, handler) => {
    if (handler(newNode)) {
      const lastId = getLastId(treeData) + 1;
  
      setTreeData([
        ...treeData,
        {
          ...newNode,
          id: lastId
        }
      ]);
  
      setOpen(false);
    }
  };

    // 绑定表具
  const handleLink = async (id, handler) => {
    // console.log(handler);
    if (handler(id)) {
        console.log(id);
    }
  };

  // 取消新增树
  const handleCance = (handler) => {
    handleCloseDialog()
    if (handler()) {
  
      setOpen(false);
    }
  };
  


  return (
    <ThemeProvider theme={theme}>
      {/* <div>
        <Input placeholder="请输入内容" onChange={handleChangeText} value={serachValue} style={{"width":"30%","marginRight":"5px"}} />
        <Button style={{"background":"#20A0FF","color":"white"}} onClick={handleSerach}>搜索</Button>
        <Button style={{"background":"#20A0FF","color":"white"}} onClick={e=>{setSerachValue('')}}>清空</Button>
      </div> */}
      <CssBaseline />
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div className={styles.app}>
          <div>
            {/* <Button onClick={handleOpenDialog} startIcon={<AddIcon />}>
             新增
           </Button> */}
            {/* { props.func.add ? <button className='btn btn-primary btn-xs' onClick={ handleOpenDialog }>新增</button> : null} */}
            {open && (
              <AddDialog
                djlb = {props.djlb}
                roles = {props.roles}
                add_child = {add_node}
                editor_zz = {editor_zz}
                tree={treeData}
                onClose={()=>handleCance(props.onCanceAdd)}
                onSubmit={(newNode)=>{
                  Boolean(newNode.isEditor) ? handleEditor(newNode.id, newNode, props.onEdit) :
                  handleSubmit(newNode, props.onAdd)
                }
              }
              />
            )}
          </div>
          <Tree
            ref={ref}
            initialOpen={initialOpenTmp}
            canDrag = {()=>{return false}}
            extraAcceptTypes={ true }
            tree={treeData}
            rootId={0}
            sort={false}
            onChangeOpen={(e)=>{localStorage.setItem('initialOpenTmp', JSON.stringify(e))}}
            render={(node, options) => (
              <CustomNode
                isNoCaozuo = {props.isNoCaozuo}
                node={node}
                {...options}
                onAddChild={(id, value)=>{
                  handleAddChild(id, value, props.onAddChild)
                }}
                onClickList={(clickNode)=>{
                  onClickListTree(clickNode, props.onClickList)
                }}
                onDelete={(id)=>{
                  handleDelete(id, props.onDelete)
                }
              }
                onCopy={(id)=>{
                  handleCopy(id, props.onCopy)
                }
              }
                onEditor={(id, value)=>{
                  handleOpenDialog()
                  setEditorZhuzhi(value)
                }
              }
                onLink={(value)=>{
                  handleLink(value, props.onLink)
                }}
              />
            )}
            dragPreviewRender={(monitorProps) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            onDrop={handleDrop}
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget
            }}
          />
        </div>
      </DndProvider>
    </ThemeProvider>
  );
}

export default TreeView2;
