import React, { useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete
} from "@mui/material";
import styles from "./AddDialog.module.css";


export const AddDialog = (props) => {

  const [text, setText] = useState( props.editor_zz ? props.editor_zz.text : "");
  const [bz, setBz] = useState("");
  const [selectedNode, setSelectedNode] = useState("");
  const [fileType, setFileType] = useState("text");
  const [parent, setParent] = useState(props.add_child === '' ? 0 : props.add_child);
  const [iszuzhi, setIszuzhi] = useState(false);
  const [djlb, setDjlb] = useState("");

  const handleChangeText = (e) => {
    setText(e.target.value);
  };
  const handleChangeParent = (e, id) => {
    setParent(id);
    setSelectedNode(id)
  };
  const handleChangeDjlb = (e, id, lxmc) => {
    setDjlb(lxmc);
  };
  const handleChangeIszuzhi = (e) => {
    setIszuzhi(e.target.checked);
  };
  const handleChangeFileType = (e) => {
    setFileType(e.target.value);
  };
  const handleChangeBz = (e) => {
    setBz(e.target.value);
  };


  return (
    <Dialog contentStyle={{width: '40%'}} open={true} onClose={props.onClose}>
      <DialogTitle>{ props.editor_zz ? `修改部门`: '新增部门'}</DialogTitle>
      <DialogContent className={styles.content} style={{'paddingTop': '10px'}}>
        <div>
          <TextField label="名称" style={{'width': '100%'}} onChange={handleChangeText} value={text} />
        </div>
        {
          props.add_child || props.editor_zz ? null :
        <div>
          {/* 选择上级 */}
          <FormControl className={styles.select}>
            <Autocomplete
              // value={ selectedNode }
              onChange={(e, newValue) => {
                handleChangeParent(e, newValue?.id); // 获取选中节点的 node.id
              }}
              options={props.tree
                .filter((node) => (node.droppable === true || node.id === parent) && node.jb ===1)
                }
              getOptionLabel={(node) => node ? node.text : '选择上级(默认根)'}
              renderInput={(params) => (
                <TextField style={{'width': '100%'}} {...params} label="选择上级(默认根)" />
              )}
            />
          </FormControl>
        </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>取消</Button>
        <Button
          disabled={text === ""}
          onClick={() =>
              props.onSubmit(props.editor_zz ? {
                zzmc: text, id: props.editor_zz.id, isEditor: true,
                pid: props.editor_zz.pid
              } : {
                zzmc: text, pid: parent, jb: 1,
              })
          }
        >
          提交
        </Button>
      </DialogActions>
    </Dialog>
  );
};
